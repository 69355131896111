import React, { useState } from 'react';
import Logo from '../Logo';
import Logo_png from '../Logo_png';
import Modal from './Modal';
import Login from './Login';
import Register from './Register';
import UserLoggedInIcon from '../UserLoggedInIcon';
import { useUser } from './UserContext';
import HamburgerMenu from './HamburgerMenu';
import { useCart } from '../components/CartContext';
import CartIcon from './CartIcon';
import styles from '../css/modules/Header.module.css';
import modalStyles from '../css/modules/Modal.module.css';

const Header = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const { user, logout } = useUser();
  const { cartItems } = useCart();

  const switchToRegister = () => {
    setIsLoginModalOpen(false);
    setIsRegisterModalOpen(true);
  };

  return (
    <header className={styles.headerBackground}>
      <div className={styles.logoContainer}>
        <Logo_png size="100px" />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.navItems}>
          {user ? (
            <>
              {user.sub || user.name ? (
                <h1>Witaj, {user.name}!</h1>
              ) : (
                <h1>Witaj, Graczu!</h1>
              )}
              <button onClick={logout} className={styles.logoutButton}>
                Wyloguj
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => setIsLoginModalOpen(true)}
                className={styles.authButton}
              >
                Logowanie
              </button>
              <Modal
                isOpen={isLoginModalOpen}
                onClose={() => setIsLoginModalOpen(false)}
              >
                <Login
                  closeModal={() => setIsLoginModalOpen(false)}
                  switchToRegister={switchToRegister}
                />
              </Modal>
              <button
                onClick={() => setIsRegisterModalOpen(true)}
                className={styles.authButton}
              >
                Rejestracja
              </button>
              <Modal
                isOpen={isRegisterModalOpen}
                onClose={() => setIsRegisterModalOpen(false)}
                extraClass={modalStyles.regModalContent}
              >
                <Register closeModal={() => setIsRegisterModalOpen(false)} />
              </Modal>
            </>
          )}
          {/* Wyświetlenie ikony koszyka, gdy w koszyku są produkty */}
          {cartItems.length > 0 && <CartIcon />}
        </div>
        <div className={styles.hamburgerContainer}>
          <HamburgerMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
