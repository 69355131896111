import React, { useState } from 'react';
import styles from '../css/modules/PricingOptions.module.css';
import { useCart } from '../components/CartContext';
import CartModal from '../components/CartModal';

const pricingOptions = [
  {
    id: 1,
    name: '1 dzień',
    duration: '1',
    price: `${process.env.REACT_APP_PRICE_FIRST} zł`,
    description: 'Dostęp do testów na jeden dzień – idealny start!'
  },
  {
    id: 2,
    name: '1 tydzień',
    duration: '7',
    price: `${process.env.REACT_APP_PRICE_SECOND} zł`,
    description: 'Pełen dostęp do testów przez 7 dni – więcej czasu na naukę. Dodatkowe funkcjonalności!'
  },
  {
    id: 3,
    name: '1 miesiąc',
    duration: '30',
    price: `${process.env.REACT_APP_PRICE_THIRD} zł`,
    description: 'Najlepsza oferta! Testy przez 30 dni – oszczędzaj i ucz się systematycznie. Maximum funkcjonalności!'
  }
];

const PricingOptions = () => {
  const { addToCart } = useCart();
  const [isModalOpen, setModalOpen] = useState(false);

  const handlePurchase = (option) => {
    addToCart(option);
    setModalOpen(true);
  };

  return (
    <>
      <div className={styles.container}>
        {/* <video
          autoPlay
          muted
          loop
          playsInline
          webkitplaysinline="true"
          className={styles.backgroundVideo}
          poster="/backgrounds/pistolet_price_offer_bg.png"
        >
          <source src="/backgrounds/why_cyberdriver_video_small.mp4" type="video/mp4" />
          Twoja przeglądarka nie obsługuje odtwarzania wideo.
        </video> */}
        <div className={styles.overlay}></div>

        <div className={styles.content}>
          <h1>Wybierz najlepszy plan dla siebie</h1>
          <p className={styles.intro}>
            Znajdź idealny pakiet na naukę do egzaminu na pozwolenie na broń w 2025!
          </p>
          <div className={styles.pricingGrid}>
            {pricingOptions.map(option => (
              <div key={option.id} className={styles.pricingCard}>
                {option.id === 2 && <div className={styles.badge}>BEST SELLER</div>}
                <h2>{option.name}</h2>
                {option.id === 2 && <p className={styles.discountPrice}>79 zł</p>}
                <p className={styles.price}>{option.price}</p>
                <p className={styles.description}>{option.description}</p>
                <button 
                  className={styles.buyButton} 
                  onClick={() => handlePurchase(option)}
                >
                  Dodaj do koszyka
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <CartModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default PricingOptions;
