// src/components/EnemyWindow.jsx
import React from 'react';
import EnergyBar from './EnergyBar';
import styles from '../css/modules/EnemyWindow.module.css';
import Typewriter from './Typewriter';

const EnemyWindow = ({ enemy }) => {
  if (!enemy) {
    return <div className={styles.window}>Brak danych przeciwnika</div>;
  }

  return (
    <div className={styles.window}>
      <div className={styles.header}>
        <img src={enemy.image} alt={enemy.name} className={styles.image} />
        <div className={styles.details}>
          <Typewriter 
            text={enemy.name}
            typingSpeed={100}
          />
          <div className={styles.energyWrapper}>
            {/* Przekazujemy current jako procent (0-100) */}
            <EnergyBar current={enemy.energy} label="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnemyWindow;
