// src/components/BottomBarDriving.jsx
import React from 'react';
import styles from '../css/modules/BottomBar.module.css';

const BottomBarDriving = ({
  currentQuestionIndex,
  questionsLength,
  onNext,
  onToggleExplanation,
  explanationVisible
}) => {
  return (
    <div className={styles.bottomBar}>
      <button
        className={styles.explanationButton}
        onClick={onToggleExplanation}
      >
        {explanationVisible ? 'Ukryj wyjaśnienie' : 'Wyjaśnienie'}
        <span className={styles.arrow}>
          {explanationVisible ? '▲' : '▼'}
        </span>
      </button>
      <button
        className={styles.navButton}
        onClick={onNext}
      >
        {currentQuestionIndex === questionsLength - 1 ? 'Zakończ misję' : 'Następne'}
      </button>
    </div>
  );
};

export default BottomBarDriving;
