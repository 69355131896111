// src/ContactForm.jsx
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../css/modules/ContactForm.module.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const defaultUserData = {
  image: '/my-photo.webp',
  name: 'Gracz',
  energy: 100,
  score: 550,
  ranking: 0,
  level: 1,
  artefacts: [],
};

const ContactForm = () => {
  const location = useLocation();
  const passedUserData = location.state?.userData;
  // Jeśli użytkownik jest zalogowany, używamy danych z kontekstu; w przeciwnym razie – danych przekazanych lub domyślnych.
  const [userData] = useState(passedUserData || defaultUserData);

  // Ustawiamy statyczne tło
  const backgroundImageUrl = `/backgrounds/1.webp`;

  const containerStyle = {
    backgroundImage: `linear-gradient(135deg, rgba(13, 13, 13, 0.7), rgba(26, 26, 26, 0.7)), url(${backgroundImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const [formData, setFormData] = useState({
    email: '',
    telephone: '',
    title: '',
    content: '',
  });
  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Funkcja walidująca poprawność emaila przy użyciu prostego wyrażenia regularnego
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedbackMessage(null);

    // Weryfikacja poprawności emaila
    if (!isValidEmail(formData.email)) {
      setFeedbackMessage('Proszę podać poprawny adres email.');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${API_BASE_URL}/cyber/send_message`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        setFeedbackMessage(data.message || 'Błąd przy wysyłaniu wiadomości.');
      } else {
        setFeedbackMessage('Wiadomość została wysłana pomyślnie! Odpowiemy możliwie najszybciej');
        setFormData({
          email: '',
          telephone: '',
          title: '',
          content: '',
        });
      }
    } catch (error) {
      console.error('Błąd:', error);
      setFeedbackMessage('Wystąpił błąd. Spróbuj ponownie.');
    }
    setLoading(false);
  };

  return (
    <div className={styles.contactContainer} style={containerStyle}>
      <h2>Formularz kontaktowy</h2>
      <form onSubmit={handleSubmit} className={styles.contactForm}>
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Telefon (opcjonalnie):
          <input
            type="text"
            name="telephone"
            value={formData.telephone}
            onChange={handleChange}
          />
        </label>
        <label>
          Tytuł:
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Treść wiadomości:
          <textarea
            name="content"
            value={formData.content}
            onChange={handleChange}
            required
          />
        </label>
        <button type="submit" disabled={loading}>
          {loading ? 'Wysyłanie...' : 'Wyślij'}
        </button>
        {feedbackMessage && (
        <div className={styles.responseContainer}>
          {feedbackMessage}
        </div>
      )}
      </form>
    </div>
  );
};

export default ContactForm;
