import React from 'react';
import styles from '../css/modules/PurchaseRegistration.module.css';

const RegistrationForm = ({
  name, setName,
  email, setEmail,
  password, setPassword,
  confirmPassword, setConfirmPassword,
  selectAll, setSelectAll,
  acceptTerms, setAcceptTerms,
  acceptMarketing, setAcceptMarketing,
  acceptPartners, setAcceptPartners
}) => {
  return (
    <>
      <div>
        <input
          type="text"
          id="name"
          placeholder="Nazwa użytkownika"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div>
        <input
          type="email"
          id="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div>
        <input
          type="password"
          id="password"
          placeholder="Hasło"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <div>
        <input
          type="password"
          id="confirmPassword"
          placeholder="Potwierdź hasło"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
      </div>
      <div className={styles.checkboxGroup}>
        <div className={styles.checkboxItem}>
          <input
            type="checkbox"
            id="selectAll"
            checked={selectAll}
            onChange={(e) => setSelectAll(e.target.checked)}
          />
          <label htmlFor="selectAll">Zaznacz wszystkie</label>
        </div>
        <div className={styles.checkboxItem}>
          <input
            type="checkbox"
            id="acceptTerms"
            checked={acceptTerms}
            onChange={(e) => setAcceptTerms(e.target.checked)}
          />
          <label htmlFor="acceptTerms">
            Oświadczam, że zapoznałem się z regulaminem i akceptuję jego treść.
          </label>
        </div>
        <div className={styles.checkboxItem}>
          <input
            type="checkbox"
            id="promo_notifications"
            checked={acceptMarketing}
            onChange={(e) => setAcceptMarketing(e.target.checked)}
          />
          <label htmlFor="promo_notifications">
            Chcę otrzymywać informacje o promocjach i specjalnych ofertach w serwisie.
          </label>
        </div>
        <div className={styles.checkboxItem}>
          <input
            type="checkbox"
            id="acceptPartners"
            checked={acceptPartners}
            onChange={(e) => setAcceptPartners(e.target.checked)}
          />
          <label htmlFor="acceptPartners">
            Wyrażam zgodę* na otrzymywanie od Usługodawcy informacji handlowych drogą elektroniczną dotyczących produktów i usług partnerów.
          </label>
        </div>
        <div className={styles.checkboxItem}>
          <br />
          * Wyrażoną zgodę możesz w każdej chwili wycofać.
        </div>
      </div>
    </>
  );
};

export default RegistrationForm;
