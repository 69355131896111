// src/TimerBar.jsx
import React from 'react';
import styles from '../css/modules/TimerBar.module.css';
import AnalogCounter from './AnalogCounter';

const TimerBar = ({ timeLeft, maxTime, active }) => {
  const safeTimeLeft = typeof timeLeft === 'number' ? timeLeft : maxTime;
  const percentage = (safeTimeLeft / maxTime) * 100;
  return (
    <div className={styles.timerBarContainer}>
      <div className={styles.timerBarBackground}>
        <div 
          className={styles.timerBarFill} 
          style={{ 
            width: `${percentage}%`,
            transition: active ? 'width 1s linear' : 'none'
          }} 
        />
      </div>
      <AnalogCounter value={safeTimeLeft} digitCount={2} />
    </div>
  );
};

export default TimerBar;
