import React, { useState } from 'react';
import styles from '../css/modules/Artefact.module.css';
import { useUser } from './UserContext';

const Artefact = ({ artifact, API_BASE_URL, onConsume }) => {
  const { user } = useUser();
  // Używamy user.sub jako email, jeżeli istnieje; w przeciwnym razie próbujemy pobrać user.email
  const email = user?.sub || user?.email;
  
  const slug =
    artifact && typeof artifact === 'object'
      ? (artifact.slug || artifact.name)
      : artifact;
  const description =
    artifact && typeof artifact === 'object' ? artifact.description : '';
  const value =
    artifact && typeof artifact === 'object' ? artifact.value : 0;

  const [hover, setHover] = useState(false);
  const [used, setUsed] = useState(false);

  const handleClick = async (e) => {
    e.stopPropagation();
    if (used) return;

    // Walidacja, czy mamy email oraz artifact_slug
    if (!email) {
      console.error('Błąd: Wymagany jest email z kontekstu');
      return;
    }
    if (!slug) {
      console.error('Błąd: Wymagany jest artifact_slug');
      return;
    }

    // Przygotowanie danych żądania
    const payload = {
      email,            // np. "t.z.ostrowski@gmail.com" pobrane z user.sub
      artifact_slug: slug  // np. "energy100"
    };

    console.log('Dane żądania:', payload);
    console.log("Artefact clicked:", slug);
    
    try {
      const response = await fetch(`${API_BASE_URL}/cyber/consume_artifact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      
      // Pobieramy treść odpowiedzi niezależnie od statusu HTTP
      const data = await response.json();
      
      if (!response.ok) {
        // Jeśli backend zwraca błąd, zgłaszamy wyjątek z komunikatem zwróconym przez backend
        throw new Error(data.error || 'Błąd podczas konsumpcji artefaktu');
      }
      
      console.log('Backend zwrócił dane:', data);
      if (onConsume) {
        onConsume(data);
      }
      setUsed(true);
    } catch (error) {
      console.error(`Błąd podczas konsumpcji artefaktu: ${error.message}`, error);
    }
  };

  return (
    <div
      className={`${styles.artefactContainer} ${used ? styles.used : ''}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleClick}
    >
      <img
        src={`/artefacts/${slug}.webp`}
        alt={slug}
        className={styles.artefactImage}
      />
      {hover && description && (
        <div className={styles.artefactOverlay}>
          <p>{description}</p>
          <p>Wartość: {value}</p>
          <button onClick={handleClick} disabled={used}>
            {used ? 'Użyty' : 'Użyj'}
          </button>
        </div>
      )}
    </div>
  );
};

export default Artefact;
