import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './css/modules/Logo.module.css';

const Logo = () => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate('/');
  };

  return (
    <div className={styles.logoContainer} onClick={navigateToHome}>
      <img 
        src="/logo_pistolet.webp" 
        alt="Logo" 
        width="100" 
        height="80"
      />
    </div>
  );
};

export default Logo;
