import React, { useEffect, useState } from 'react';
import styles from '../css/modules/AnalogCounter.module.css';

// Ustawiamy wysokość pojedynczej cyfry zgodnie z nowymi wymiarami (25px)
const DIGIT_HEIGHT = 25;

const RollingDigit = ({ digit }) => {
  const [prevDigit, setPrevDigit] = useState(digit);
  const [currentDigit, setCurrentDigit] = useState(digit);

  useEffect(() => {
    if (digit !== currentDigit) {
      setPrevDigit(currentDigit);
      setTimeout(() => {
        setCurrentDigit(digit);
      }, 10);
    }
  }, [digit, currentDigit]);

  // Obliczenie przesunięcia, aby uzyskać efekt "rolling" w górę
  const getTranslateY = () => {
    if (prevDigit === currentDigit) {
      return currentDigit * DIGIT_HEIGHT;
    }
    return (10 + currentDigit) * DIGIT_HEIGHT;
  };

  return (
    <div className={styles.digitContainer}>
      <div
        className={styles.rollingContainer}
        style={{ transform: `translateY(-${getTranslateY()}px)` }}
      >
        {[...Array(20).keys()].map((num, index) => (
          <div key={index} className={styles.digit}>
            {num % 10}
          </div>
        ))}
      </div>
    </div>
  );
};

const AnalogCounter = ({ value }) => {
  // Konwertujemy wartość na string i dzielimy ją na poszczególne cyfry.
  // Liczba pól będzie równa długości podanego inputu.
  const digits = value.toString().split('');

  return (
    <div className={styles.counter}>
      {digits.map((digit, index) => (
        <RollingDigit key={index} digit={parseInt(digit, 10)} />
      ))}
    </div>
  );
};

export default AnalogCounter;
