// src/ExamQuestions7.jsx
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../css/modules/ExamQuestions.module.css';

const ExamQuestions7 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/egzamin-pozwolenie-na-bron-testy-patent-strzelecki');
  };

  return (
    <div className={styles.contactContainer}>
      <Helmet>
        <title>Patent strzelecki – szczegółowe dane broni i amunicji ISSF (część 7)</title>
        <meta
          name="description"
          content="Zestaw pytań egzaminacyjnych dotyczących parametrów technicznych broni i amunicji w konkurencjach ISSF. Poznaj zasady dotyczące kalibrów, długości luf, oporu spustu i ograniczeń w ubiorze."
        />
      </Helmet>

      <div className={styles.content}>
        <h1 className="mainHeader">🎯 Pytania egzaminacyjne – broń i amunicja ISSF (część 7)</h1>

        <div className="intro">
          📘 Ten zestaw pytań obejmuje zasady obowiązujące w sportach strzeleckich według przepisów ISSF.
          Przed Tobą zestaw oficjalnych pytań egzaminacyjnych opublikowanych przez Polski Związek Strzelectwa Sportowego. Na ich podstawie przygotowaliśmy nasze własne, praktyczne opracowanie – z myślą o kandydatach na patent strzelecki.
         👉 Rozwiąż test i przekonaj się, czy zdałbyś egzamin już dziś!
        </div>

        <h2>🚫 Zakazana amunicja i definicje kalibrów</h2>
        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>94. ❌ Jakiej amunicji nie można używać w zawodach ISSF?</h3>
            <p>
              Zabroniona jest amunicja:
              <ul>
                <li>🔹 z pociskami podkalibrowymi lub z elementami z tworzyw sztucznych,</li>
                <li>🔹 z materiałami wybuchowymi lub zapalającymi,</li>
                <li>🔹 zawierająca rzadkie lub bardzo twarde metale,</li>
                <li>🔹 wytworzona niefabrycznie (chyba że do użytku własnego i przez osobę z pozwoleniem).</li>
              </ul>
            </p>
          </li>

          <li className={styles.customAnswer}>
            <h3>95. 🎯 Czym jest kaliber broni?</h3>
            <p>To średnica wewnętrzna lufy lub pocisku wyrażona w milimetrach lub calach (np. 5,6 mm = .22").</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>96. ⚖️ Co oznacza kaliber wagomiarowy?</h3>
            <p>To liczba kul odlewanych z jednego funta ołowiu (np. kaliber 12 – z 1 funta odlewa się 12 kul).</p>
          </li>
        </ul>

        <h2>📏 Parametry broni w konkurencjach ISSF</h2>
        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>97. 📏 Jaka jest maksymalna długość lufy pistoletu dowolnego?</h3>
            <p>Brak ograniczeń długości lufy – zawodnik może używać konstrukcji dowolnej, o ile spełnia pozostałe przepisy.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>98. 📏 Jaka jest maksymalna długość lufy pistoletu sportowego?</h3>
            <p>Lufa może mieć maksymalnie 153 mm długości, zgodnie z regulaminem ISSF.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>99. ⚖️ Jaka jest maksymalna masa karabinu pneumatycznego?</h3>
            <p>5,5 kg – waga ta obejmuje również wszystkie zamontowane akcesoria.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>100. ⚖️ Czy istnieje ograniczenie masy pistoletu dowolnego?</h3>
            <p>Nie – w konkurencji „pistolet dowolny” nie ma ograniczeń wagowych.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>101. ⚖️ Jaka jest maksymalna masa pistoletu sportowego?</h3>
            <p>1400 g – waga broni z akcesoriami nie może przekraczać tej wartości.</p>
          </li>
        </ul>

        <h2>🎯 Opór spustu i wymiary broni</h2>
        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>102. 💡 Minimalny opór języka spustowego w pistolecie dowolnym?</h3>
            <p>Bez ograniczeń – zawodnik może dowolnie wyregulować opór spustu.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>103. 💡 Minimalny opór języka spustowego w pistolecie pneumatycznym?</h3>
            <p>500 g – zgodnie z przepisami ISSF.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>104. 💡 Minimalny opór języka spustowego w pistolecie sportowym?</h3>
            <p>1000 g – zapewnia bezpieczeństwo i standaryzację strzałów.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>105. 📦 Jakie wymiary musi spełniać pistolet sportowy?</h3>
            <p>Musi mieścić się w pudełku o wymiarach 300 × 150 × 50 mm – jest to jeden ze sposobów technicznej kontroli zgodności broni.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>106. 📦 Jakie wymiary musi spełniać pistolet pneumatyczny?</h3>
            <p>Pudełko 420 × 200 × 50 mm – zgodność sprawdzana jest przed startem w zawodach.</p>
          </li>
        </ul>

        <h2>🔄 Inne zasady ISSF</h2>
        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>107. 🔁 Czy można używać rewolweru w konkurencji „Pistolet sportowy 25 m”?</h3>
            <p>Tak – o ile rewolwer spełnia wszystkie parametry wymagane przez ISSF (kaliber, masa, wymiary, opór spustu).</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>108. 💥 Ile naboi ładuje się do pistoletu dowolnego?</h3>
            <p>Jeden nabój – konkurencja pistoletu dowolnego zakłada pojedynczy strzał w każdej serii.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>109. 👕 Jakie są zasady dotyczące ubioru zawodników?</h3>
            <p>Strój nie może wspomagać zawodnika – nie może podpierać ciała. Buty nie mogą zakrywać kostek. Ma to na celu wyrównanie szans i zapobieganie nadużyciom.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>110. 🧍 Czym jest konkurencja „karabin dowolny 60 strzałów leżąc”?</h3>
            <p>To strzelanie do tarczy z odległości 50 m w pozycji leżącej. Zawodnik oddaje 60 strzałów ocenianych zgodnie z regulaminem ISSF.</p>
          </li>
        </ul>

        <div className="buttonContainer">
          <button onClick={handleNext} className={styles.secondaryButton}>
            Do kolejnych pytań
          </button>
          <button onClick={() => navigate('/')} className={styles.primaryButton}>
            Sprawdź testy na broń
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamQuestions7;
