import React, { useState, useEffect } from 'react';
import styles from '../css/modules/Answers.module.css';

const Answers = ({
  answerKeys,
  currentQuestion,
  selectedAnswers,
  isChecked,
  toggleAnswerSelection,
  initialDelay = 300,     // opóźnienie przed pojawieniem się pierwszej odpowiedzi (ms)
  appearanceDelay = 300,  // opóźnienie między kolejnymi odpowiedziami (ms)
  onAnimationEnd,         // callback wywoływany, gdy wszystkie odpowiedzi są widoczne
}) => {
  const [visibleCount, setVisibleCount] = useState(0);

  useEffect(() => {
    // Zakładamy, że answerKeys są stałe przez czas życia komponentu,
    // dlatego nie umieszczamy ich w zależnościach – efekt uruchomi się tylko raz przy montażu.
    setVisibleCount(0);
    let intervalId;
    const timeoutId = setTimeout(() => {
      intervalId = setInterval(() => {
        setVisibleCount(prevCount => {
          if (prevCount < answerKeys.length) {
            const newCount = prevCount + 1;
            if (newCount === answerKeys.length && typeof onAnimationEnd === 'function') {
              onAnimationEnd();
            }
            return newCount;
          } else {
            clearInterval(intervalId);
            return prevCount;
          }
        });
      }, appearanceDelay);
    }, initialDelay);

    // Cleanup: czyścimy timeout i interval przy odmontowaniu
    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, [initialDelay, appearanceDelay, onAnimationEnd]); // efekt uruchamia się tylko przy pierwszym montażu

  return (
    <div className={styles.answers}>
      {answerKeys.slice(0, visibleCount).map((key) => {
        const answerText = currentQuestion[key];
        const isSelected = selectedAnswers.includes(key);
        const isCorrect = currentQuestion.correctAnswer.includes(key);
        let buttonClasses = [styles.answerButton];

        if (isChecked) {
          if (isCorrect) {
            buttonClasses.push(styles.correct);
          } else if (isSelected && !isCorrect) {
            buttonClasses.push(styles.incorrect);
          }
        } else if (isSelected) {
          buttonClasses.push(styles.selected);
        }

        return (
          <button
            key={key}
            onClick={() => toggleAnswerSelection(key)}
            className={buttonClasses.join(' ')}
            disabled={isChecked}
          >
            {answerText}
          </button>
        );
      })}
    </div>
  );
};

export default Answers;
