import React, { useState, useMemo, useEffect } from 'react';
import EnergyBar from './EnergyBar';
import AnalogCounter from './AnalogCounter';
import styles from '../css/modules/UserWindow.module.css';
import { useUser } from './UserContext';
import Artefact from './Artefact';
import { useUserInfo } from '../hooks/useUserInfo';
import { useLocation } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UserWindow = ({
  image: defaultImage,
  name: defaultName,
  energy: defaultEnergy,
  score: defaultScore,
  ranking,
  level: defaultLevel
}) => {
  const { user, setUser, userVersion } = useUser();
  const { userInfo, loading } = useUserInfo();
  const location = useLocation();

  // Lokalny stan do wymuszenia pełnego remountu przy zmianie userVersion
  const [localKey, setLocalKey] = useState(Date.now());
  useEffect(() => {
    setLocalKey(Date.now());
  }, [userVersion]);

  const userData = useMemo(() => ({
    score: user?.score ?? defaultScore ?? 0,
    energy: user?.energy ?? defaultEnergy ?? 100,
    artefacts: user?.artefacts ?? [],
    image: user?.image || defaultImage,
    name: user?.name || defaultName,
    level: user?.level ?? defaultLevel ?? 0,
  }), [user, userVersion, defaultScore, defaultEnergy, defaultImage, defaultName, defaultLevel]);

  const processImagePath = (image) => {
    const rawImage = image.startsWith('/') ? image : `/${image}`;
    return rawImage.endsWith('.webp') ? rawImage : `${rawImage}.webp`;
  };

  if (loading) {
    return (
      <div className={styles.window}>
        <div className={styles.loading}>Ładowanie...</div>
      </div>
    );
  }

  return (
    <div 
      className={styles.window} 
      key={`${location.key}-${localKey}`} // klucz wymuszający pełny remount przy zmianie userVersion
    >
      <div className={styles.content}>
        {/* Zdjęcie użytkownika */}
        <div className={styles.imageContainer}>
          <img 
            src={processImagePath(userData.image)} 
            alt={userData.name} 
            className={styles.image} 
          />
        </div>

        {/* Informacje o użytkowniku */}
        <div className={styles.info}>
          <div className={styles.header}>
            <div className={styles.nameContainer}>
            <p className={styles.name}>{userData.name.substring(0, 10)}</p>
              <span className={styles.levelBadge}>{userData.level}</span>
            </div>
            <div className={styles.counterContainer}>
              <AnalogCounter value={userData.score} />
            </div>
          </div>

          <EnergyBar 
            current={userData.energy} 
            label="" 
          />

          <div className={styles.artifactsGrid}>
            {userData.artefacts.slice(0, 4).map((artifact, index) => {
              const artifactSlug =
                artifact && typeof artifact === 'object'
                  ? (artifact.slug || artifact.name)
                  : artifact;

              return (
                <Artefact
                  key={index}
                  artifact={artifact}
                  API_BASE_URL={API_BASE_URL}
                  onConsume={(data) => {
                    setUser(prevUser => {
                      if (!prevUser) return prevUser;
                      let removed = false;
                      const newArtefacts = (prevUser.artefacts || []).filter(a => {
                        const aSlug =
                          a && typeof a === 'object'
                            ? (a.slug || a.name)
                            : a;
                        if (!removed && aSlug === artifactSlug) {
                          removed = true;
                          return false;
                        }
                        return true;
                      });
                      return {
                        ...prevUser,
                        energy: data.new_energy ?? prevUser.energy,
                        score: data.new_score ?? prevUser.score,
                        artefacts: newArtefacts,
                      };
                    });
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserWindow;
