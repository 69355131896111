// src/ExamQuestions3.jsx
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../css/modules/ExamQuestions.module.css';

const ExamQuestions3 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/egzamin-na-bron-odpowiedzi-do-najczestrzych-pytan');
  };

  return (
    <div className={styles.contactContainer}>
      <Helmet>
        <title>Patent strzelecki – pytania egzaminacyjne (część 4)</title>
        <meta
          name="description"
          content="Czwarta część pytań egzaminacyjnych na patent strzelecki z wyjaśnieniami i ikonami. Dowiedz się, jak bezpiecznie używać, przewozić i przechowywać broń."
        />
      </Helmet>

      <div className={styles.content}>
        <h1 className="mainHeader">🎯 Pytania egzaminacyjne na patent strzelecki – część 4</h1>

        <div className="intro">
        Każdy przyszły strzelec sportowy musi zmierzyć się z teorią. Oficjalna lista pytań egzaminacyjnych przygotowana przez Polski Związek Strzelectwa Sportowego to podstawa – a my opracowaliśmy ją z myślą o czytelności, zrozumieniu i skutecznej nauce. Sprawdź nasze autorskie wersje pytań z dokumentu PZSS.
          📘 Zbiór pytań z zasadami noszenia, przewożenia i używania broni palnej na strzelnicy i poza nią. Pamiętaj jednak, żeby przede wszystkim rozwiązywać nasze testy z wyjaśnieniami. Część z tych pytań pochodzi z prawdziwych egzaminów.
        </div>

        <h2>📋 Zasady używania, noszenia i cofnięcia pozwolenia</h2>
        <ul className="reasonList">
          <li className={styles.customAnswer}><h3>47. ❌ Naruszenie zasad używania broni na strzelnicy</h3><p>W przypadku używania broni poza strzelnicą przez osobę posiadającą pozwolenie wyłącznie do celów sportowych lub szkoleniowych, Policja może cofnąć pozwolenie na broń.</p></li>
          <li className={styles.customAnswer}><h3>48. 🚶‍♂️ Definicja noszenia broni</h3><p>W rozumieniu ustawy noszenie broni oznacza każdy sposób przemieszczania załadowanej broni przez osobę, która posiada na nią pozwolenie.</p></li>
          <li className={styles.customAnswer}><h3>49. 🚫 Obowiązkowe cofnięcie pozwolenia na broń</h3><p>Dotyczy osób z zaburzeniami psychicznymi, istotnymi zaburzeniami psychologicznymi oraz skazanych prawomocnie za określone przestępstwa – umyślne i nieumyślne.</p></li>
          <li className={styles.customAnswer}><h3>50. ⚠️ Kiedy Policja może cofnąć pozwolenie na broń?</h3><p>W przypadku naruszenia obowiązku rejestracji broni lub ustania przyczyn, dla których wydano pozwolenie.</p></li>
          <li className={styles.customAnswer}><h3>51. 🍺 Noszenie broni po alkoholu lub narkotykach</h3><p>Osoba taka podlega karze aresztu lub grzywny. Prawo zabrania noszenia broni pod wpływem środków odurzających.</p></li>
          <li className={styles.customAnswer}><h3>52. 🌍 Wywóz broni za granicę</h3><p>Wymaga uzyskania zgody od właściwego organu Policji.</p></li>
        </ul>

        <h2>🎯 Kryteria i dokumenty związane z pozwoleniem</h2>
        <ul className="reasonList">
          <li className={styles.customAnswer}><h3>53. 🏅 Co uznaje się za ważną przyczynę wydania pozwolenia sportowego?</h3><p>Udział w klubie strzeleckim, kwalifikacje sportowe lub licencja związku sportowego.</p></li>
          <li className={styles.customAnswer}><h3>54. 🪪 Co potwierdza zarejestrowanie broni?</h3><p>Legitymacja posiadacza broni lub świadectwo broni.</p></li>
          <li className={styles.customAnswer}><h3>55. ⏳ Termin ważności legitymacji uprawniającej do nabycia broni</h3><p>Traci ważność po 3 miesiącach od dnia wydania.</p></li>
          <li className={styles.customAnswer}><h3>56. 📅 Jak długo ważne jest świadectwo do nabycia broni?</h3><p>Świadectwo jest ważne przez 3 miesiące.</p></li>
        </ul>

        <h2>🔐 Bezpieczeństwo na strzelnicy i w transporcie</h2>
        <ul className="reasonList">
          <li className={styles.customAnswer}><h3>57. 🚨 Zgłoszenie zaginięcia broni</h3><p>Należy niezwłocznie poinformować o tym właściwy organ Policji.</p></li>
          <li className={styles.customAnswer}><h3>58. 👥 Kto może przebywać na stanowisku strzeleckim?</h3><p>Tylko osoby korzystające ze strzelnicy mogą wchodzić na stanowiska strzeleckie.</p></li>
          <li className={styles.customAnswer}><h3>59. 🔓 Jak broń powinna być przechowywana poza stanowiskiem?</h3><p>Musi być rozładowana, z otwartą komorą nabojową, bez pasa i pokrowca.</p></li>
          <li className={styles.customAnswer}><h3>60. 🚌 Przewożenie broni w komunikacji publicznej</h3><p>Broń musi być rozładowana, bez amunicji w komorze nabojowej i magazynkach.</p></li>
          <li className={styles.customAnswer}><h3>61. 🚆 Broń sportowa w transporcie publicznym</h3><p>Musi być zabezpieczona w sposób uniemożliwiający powstanie zagrożenia życia, zdrowia lub mienia.</p></li>
          <li className={styles.customAnswer}><h3>62. 🧳 Przewóz broni sportowej</h3><p>Możliwy po okazaniu wymaganych pozwoleń i licencji, w futerale jako oddzielny bagaż.</p></li>
          <li className={styles.customAnswer}><h3>63. 🔄 Korzystanie z cudzej broni na strzelnicy</h3><p>Dozwolone tylko za zgodą właściciela broni.</p></li>
          <li className={styles.customAnswer}><h3>64. 🚫 Alkohol i narkotyki na strzelnicy</h3><p>Bezwzględnie zabronione jest spożywanie i przebywanie pod wpływem substancji psychoaktywnych.</p></li>
          <li className={styles.customAnswer}><h3>65. 🧍 Gdzie można wyjąć broń na strzelnicy?</h3><p>Wyłącznie na stanowisku strzeleckim.</p></li>
        </ul>

        <div className="buttonContainer">
          <button onClick={handleNext} className={styles.secondaryButton}>
            Kolejne
          </button>
          <button onClick={() => navigate('/')} className={styles.primaryButton}>
            Przerób testy na patent strzelecki
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamQuestions3;
