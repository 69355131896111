// src/ExamQuestions3.jsx
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../css/modules/ExamQuestions.module.css';

const ExamQuestions3 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/patent-strzelecki-pytania-i-odpowiedzi-egzamin-na-bron');
  };

  return (
    <div className={styles.contactContainer}>
      <Helmet>
        <title>Patent strzelecki – pytania egzaminacyjne (część 3)</title>
        <meta
          name="description"
          content="Zestaw pytań egzaminacyjnych na patent strzelecki z rozwiniętymi odpowiedziami – część 3. Poznaj zasady przechowywania, rejestracji i używania broni oraz wymagania prawne."
        />
      </Helmet>

      <div className={styles.content}>
        <h1 className="mainHeader">🎯 Pytania egzaminacyjne na patent strzelecki – część 3</h1>

        <div className="intro">
        Chcesz zdobyć patent strzelecki? Świetnie trafiłeś! Oto oficjalne pytania egzaminacyjne opublikowane przez Polski Związek Strzelectwa Sportowego. Dla ułatwienia nauki przygotowaliśmy nasze własne opracowanie tych zagadnień.
          📘 Kontynuuj przygotowania do egzaminu na patent strzelecki. Oto kolejne pytania z wyjaśnieniami. Ale i tak polecamy nasze testy wraz z wyjaśnieniami!
        </div>

        <h2>🔒 Przepisy i obowiązki posiadacza broni</h2>

        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>32. 🚫 Kiedy Policja obligatoryjnie cofa pozwolenie na broń?</h3>
            <p>Policja musi cofnąć pozwolenie, jeśli posiadacz nie przestrzega warunków określonych w decyzji o wydaniu pozwolenia, np. używa broni niezgodnie z jej przeznaczeniem.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>33. 🏠 Czy należy zgłosić zmianę miejsca stałego pobytu?</h3>
            <p>Tak, w terminie 14 dni należy pisemnie poinformować organ Policji właściwy dla nowego miejsca zamieszkania. Obowiązek ten wynika z potrzeby aktualizacji danych posiadacza broni.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>34. 💼 Co należy zrobić po utracie uprawnień do posiadania broni?</h3>
            <p>Osoba traci prawo do posiadania broni i ma obowiązek niezwłocznie ją zbyć wraz z amunicją – np. sprzedać lub przekazać osobie posiadającej stosowne pozwolenie.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>35. 🚷 Czy naruszenie ograniczenia noszenia broni jest przestępstwem?</h3>
            <p>Tak, osoby noszące broń wbrew zakazowi lub ograniczeniu popełniają czyn zabroniony. Policja może cofnąć pozwolenie w takiej sytuacji.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>36. 🗄️ Jak należy przechowywać broń i amunicję?</h3>
            <p>W stalowych kasach lub szafach przymocowanych do elementów konstrukcyjnych budynku lub w metalowych sejfach z odpowiednimi atestami. To wymóg wynikający z przepisów o bezpiecznym przechowywaniu.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>37. ⏱️ W jakim terminie należy zgłosić utratę broni?</h3>
            <p>Zgłoszenia należy dokonać niezwłocznie, nie później niż w ciągu 24 godzin od chwili stwierdzenia utraty broni.</p>
          </li>
        </ul>

        <h2>👥 Ograniczenia i wyjątki w posiadaniu broni</h2>

        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>38. 🚸 Kto nie może otrzymać pozwolenia na broń?</h3>
            <p>Osoby poniżej 18 roku życia oraz osoby z zaburzeniami psychicznymi są ustawowo wykluczone z możliwości uzyskania pozwolenia na broń.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>39. 🧾 W jakich przypadkach pozwolenie na broń nie jest wymagane?</h3>
            <p>Pozwolenie nie jest wymagane m.in. przy posiadaniu broni muzealnej, broni na strzelnicy, broni alarmowej do 6 mm, broni pozbawionej cech użytkowych, czy też broni czarnoprochowej sprzed 1885 r. oraz jej replik.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>40. 🎓 Jaki jest minimalny wiek do uzyskania pozwolenia (z wyjątkiem sportowego i myśliwskiego)?</h3>
            <p>Wnioskodawca musi mieć ukończone 21 lat. To ograniczenie wiekowe ma na celu zapewnienie dojrzałości i odpowiedzialności posiadacza broni.</p>
          </li>
        </ul>

        <h2>🔫 Szczegóły dotyczące poszczególnych typów broni i procedur</h2>

        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>41. 🎯 Do czego uprawnia pozwolenie na broń sportową?</h3>
            <p>Do posiadania broni palnej bocznego zapłonu z lufami gwintowanymi o kalibrze do 6 mm, zgodnie z ustawą o broni i amunicji.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>42. 📦 Czy można przesyłać broń i amunicję kurierem?</h3>
            <p>Tak, ale wyłącznie za pośrednictwem firm specjalizujących się w przewozie tego rodzaju towarów i posiadających odpowiednie zezwolenia.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>43. 🌍 Jakie dokumenty są potrzebne do przywozu broni spoza UE?</h3>
            <p>Obywatel Polski musi posiadać zaświadczenie wydane przez właściwego konsula RP. Brak dokumentu skutkuje niedopuszczeniem broni do wwozu.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>44. 📝 Na podstawie jakiego dokumentu rejestruje się broń?</h3>
            <p>Na podstawie dowodu nabycia broni (np. faktury, umowy sprzedaży) odpowiedni organ Policji lub Żandarmeria Wojskowa dokonuje rejestracji.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>45. 🧳 Co grozi za niezgłoszenie zamiaru wywozu broni za granicę?</h3>
            <p>W przypadku naruszenia wymogu uzyskania zgody, Policja może cofnąć pozwolenie na broń, traktując to jako poważne naruszenie przepisów.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>46. 🚨 Co grozi za użyczenie broni osobie nieuprawnionej?</h3>
            <p>Jest to czyn zabroniony i podlega karze grzywny lub aresztu. Przekazanie broni osobie bez stosownych uprawnień jest traktowane jako zagrożenie bezpieczeństwa.</p>
          </li>
        </ul>

        <div className="buttonContainer">
          <button onClick={handleNext} className={styles.secondaryButton}>
            Kolejne pytania
          </button>
          <button onClick={() => navigate('/')} className={styles.primaryButton}>
            Sprawdź testy egzaminacyjne na broń
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamQuestions3;
