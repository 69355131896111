import React, { useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Modal from './Modal';
import { useUser } from './UserContext';
import Login from './Login';
import Register from './Register';
import styles from '../css/modules/HamburgerMenu.module.css';
import UserProfile from './UserProfile';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const { user, logout } = useUser();

  return (
    <div className={styles.hamburgerMenu}>
      <button 
        onClick={() => setIsOpen(!isOpen)} 
        className={styles.menuIcon}
        aria-label={isOpen ? "Close menu" : "Open menu"}
      >
        {isOpen ? <IoMdClose size={30} /> : <FiMenu size={30} />}
      </button>

      {isOpen && (
        <div className={styles.menuContent}>
          {/* Ranking dostępny zawsze */}
          <Link to="/cennik" className={styles.menuText} aria-label="Ranking">
            Cennik
          </Link>
          <Link to="/ranking" className={styles.menuText} aria-label="Ranking">
            Ranking
          </Link>

          {user ? (
            <>      <button 
            onClick={() => setIsRegisterModalOpen(true)} 
            className={styles.button}
            aria-label="Profil użytkownika"
          >
            Profil użytkownika
          </button>
          <Modal isOpen={isRegisterModalOpen} onClose={() => setIsRegisterModalOpen(false)}>
            <UserProfile closeModal={() => setIsRegisterModalOpen(false)} />
          </Modal>
              <button 
                onClick={logout} 
                className={styles.button}
                aria-label="Wyloguj"
              >
                Wyloguj
              </button>
            </>
          ) : (
            <>
              <button 
                onClick={() => setIsLoginModalOpen(true)} 
                className={styles.button}
                aria-label="Logowanie"
              >
                Logowanie
              </button>
              <Modal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)}>
                <Login closeModal={() => setIsLoginModalOpen(false)} />
              </Modal>
              <button 
                onClick={() => setIsRegisterModalOpen(true)} 
                className={styles.button}
                aria-label="Rejestracja"
              >
                Rejestracja
              </button>
              <Modal isOpen={isRegisterModalOpen} onClose={() => setIsRegisterModalOpen(false)}>
                <Register />
              </Modal>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
