// src/ExamQuestionsExpanded2.jsx
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../css/modules/ExamQuestions.module.css';

const ExamQuestions2 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/odpowiedzi-na-pytania-egzamin-na-bron-strzelecki');
  };

  return (
    <div className={styles.contactContainer}>
      <Helmet>
        <title>Pytania egzaminacyjne – Patent strzelecki (cz. 2)</title>
        <meta
          name="description"
          content="Druga część rozszerzonych pytań egzaminacyjnych na patent strzelecki z wyjaśnieniami i komentarzami."
        />
      </Helmet>

      <div className={styles.content}>
        <h1 className="mainHeader">🎯 Pytania egzaminacyjne na patent strzelecki – część 2</h1>

        <div className="intro">
        Poniższe pytania pochodzą z oficjalnej bazy Polskiego Związku Strzelectwa Sportowego i są publikowane na stronie PZSS w ramach przygotowania do egzaminu na patent strzelecki. Przedstawiamy nasze autorskie opracowanie tych zagadnień, dostępnych w oficjalnym dokumencie: Pytania egzaminacyjne na patent strzelecki – PZSS (PDF).
          📘 Kontynuuj naukę z kolejnym zestawem pytań. Każde z nich zawiera rozwiniętą odpowiedź, aby lepiej zrozumieć temat. Najbadziej jednak polecamy nasze testy w formie cyberpunkowej gry wraz z wyjaśnieniami!
        </div>

        <h2>📋 Pytania i odpowiedzi (nr 16–31)</h2>

        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>🔍 16. Czy Policja może zobowiązać posiadacza pozwolenia na broń do badań lekarskich i psychologicznych?</h3>
            <p>✅ Tak. Organy Policji mają takie prawo, jeżeli istnieją uzasadnione przesłanki, że dana osoba może mieć problemy psychiczne, uzależnienie od alkoholu lub innych substancji, lub zaburzenia ograniczające zdolność do bezpiecznego posługiwania się bronią.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>🛑 17. Czy Policja może ograniczyć lub zakazać noszenia broni mimo posiadania pozwolenia?</h3>
            <p>✅ Tak. Posiadanie pozwolenia nie oznacza automatycznie prawa do noszenia broni przy sobie. Organ Policji może nałożyć ograniczenia lub całkowicie zakazać noszenia w interesie bezpieczeństwa publicznego.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>🔐 18. Czy osoba deponująca broń w jednostce Policji może ją okazać potencjalnemu nabywcy?</h3>
            <p>✅ Tak, ale tylko po uzgodnieniu z jednostką Policji, w której broń została zdeponowana. Dostęp do broni w celu sprzedaży jest możliwy, jednak wymaga zgody.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>📑 19. Czy posiadacz pozwolenia sportowego musi dostarczać aktualne orzeczenia lekarskie do Policji?</h3>
            <p>❌ Nie. Po uzyskaniu pozwolenia nie ma obowiązku okresowego dostarczania orzeczeń, chyba że Policja wyda takie polecenie w konkretnych okolicznościach.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>📋 20. Czy do wniosku o wydanie pozwolenia na broń trzeba dołączyć orzeczenia?</h3>
            <p>✅ Tak. Do wniosku należy dołączyć zarówno orzeczenie lekarskie, jak i psychologiczne potwierdzające brak przeciwwskazań do posiadania broni.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>⚖️ 21. Czy Policja może cofnąć pozwolenie osobie uchylającej się od badań?</h3>
            <p>✅ Tak. Odmowa poddania się badaniom po wezwaniu przez Policję jest podstawą do cofnięcia pozwolenia na broń.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>💥 22. Czy można posiadać amunicję wykonaną niefabrycznie?</h3>
            <p>✅ Tak, ale wyłącznie do użytku własnego, i tylko przez osoby posiadające pozwolenie na broń myśliwską, sportową lub kolekcjonerską. Nie wolno takiej amunicji zbywać.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>🎯 23. Czy kolekcjonerzy mogą strzelać z posiadanej broni?</h3>
            <p>❌ Nie. Pozwolenie kolekcjonerskie nie uprawnia do użytkowania broni na strzelnicy. Do tego potrzebne jest oddzielne pozwolenie sportowe lub myśliwskie.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>🧪 24. Jakie elementy uważa się za istotne części amunicji?</h3>
            <p>📌 M.in. pociski zawierające materiały wybuchowe lub chemiczne, spłonki oraz proch. Każdy z tych elementów może być traktowany jako amunicja i podlega ścisłej regulacji prawnej.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>🔧 25. Co jest istotną częścią broni palnej?</h3>
            <p>📌 Elementy takie jak: zamek, szkielet, lufa z komorą nabojową, komora zamkowa, bęben czy baskila. Są one traktowane jak broń i ich posiadanie bez pozwolenia jest zabronione.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>📦 26. Co należy zrobić z bronią po utracie uprawnień do jej posiadania?</h3>
            <p>📅 Broń oraz amunicję należy w ciągu 30 dni zdeponować w jednostce Policji. Niedopełnienie tego obowiązku jest wykroczeniem.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>📘 27. Które typy broni mieszczą się w definicji broni palnej?</h3>
            <p>✅ Broń bojowa, myśliwska, sportowa, gazowa, alarmowa i sygnałowa. Każda z nich podlega rejestracji i wymaga odpowiedniego pozwolenia.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>🎓 28. Od jakiego wieku można uzyskać pozwolenie na broń do celów sportowych/łowieckich?</h3>
            <p>👤 Od 18 roku życia, na wniosek szkoły, klubu sportowego lub organizacji. Osoba musi również spełniać pozostałe wymogi ustawowe.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>🕔 29. W jakim terminie należy zarejestrować nowo nabytą broń?</h3>
            <p>📅 W ciągu 5 dni od daty zakupu, w odpowiedniej jednostce Policji. Termin ten jest nieprzekraczalny.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>🏅 30. Kto jest zwolniony z egzaminu na patent strzelecki?</h3>
            <p>👮‍♂️ Funkcjonariusze Policji, wojska, służb specjalnych oraz członkowie PZSS z licencją strzelecką, którzy zdali egzamin na podstawie innych przepisów.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>💳 31. Jaka jest opłata za egzamin strzelecki?</h3>
            <p>💰 400 zł – zgodnie z ustawą z dnia 25 czerwca 2010 r. o sporcie (Dz.U. z 2023 r. poz. 2048). Opłata jest jednorazowa i obowiązkowa.</p>
          </li>
        </ul>

        <div className="buttonContainer">
          <button onClick={handleNext} className={styles.secondaryButton}>
            👉 Kolejne
          </button>
          <button onClick={() => navigate('/')} className={styles.primaryButton}>
          Rozwiąż test na patent strzelecki
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamQuestions2;
