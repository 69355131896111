import React from 'react';
import { Helmet } from 'react-helmet';

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Polityka prywatności strzelasz.pl</title>
        <meta charSet="UTF-8" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="article-container">
        <h1>Polityka prywatności strzelasz.pl</h1>

        <div className="section">
          <h2>I. Administrator danych</h2>
          <ul>
            <li>
              Administratorem danych osobowych jest Magdalena Pilch z siedzibą w Krakowie, ul. Lubicz 17, 31-075 Kraków.
            </li>
          </ul>
        </div>

        <div className="section">
          <h2>II. Zakres i cel przetwarzania danych</h2>
          <ul>
            <li>Dane osobowe są przetwarzane w celu:</li>
            <ul>
              <li>Rejestracji i utrzymania konta użytkownika.</li>
              <li>Realizacji usług oferowanych przez serwis, w tym sprzedaży testów.</li>
              <li>Kontaktowania się z użytkownikami w celach marketingowych (jeśli użytkownik wyraził na to zgodę).</li>
            </ul>
          </ul>
        </div>

        <div className="section">
          <h2>III. Podstawa prawna przetwarzania</h2>
          <ul>
            <li>
              Przetwarzanie danych osobowych odbywa się na podstawie zgody użytkownika oraz przepisów prawa, w tym umów zawartych z Usługodawcą.
            </li>
          </ul>
        </div>

        <div className="section">
          <h2>IV. Odbiorcy danych</h2>
          <ul>
            <li>
              Dane mogą być przekazywane podmiotom współpracującym z Usługodawcą, które realizują usługi związane z obsługą serwisu, wystawianiem faktur czy wysyłką informacji marketingowych.
            </li>
          </ul>
        </div>

        <div className="section">
          <h2>V. Prawa użytkownika</h2>
          <ul>
            <li>
              Użytkownik ma prawo do:
              <ul>
                <li>Dostępu do swoich danych osobowych.</li>
                <li>Poprawiania lub usunięcia swoich danych.</li>
                <li>Wycofania zgody na przetwarzanie danych osobowych.</li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="section">
          <h2>VI. Okres przechowywania danych</h2>
          <ul>
            <li>
              Dane osobowe są przechowywane przez okres niezbędny do realizacji celów, dla których zostały zebrane, lub przez okres wymagany przepisami prawa.
            </li>
          </ul>
        </div>

        <div className="section">
          <h2>VII. Zmiany polityki prywatności</h2>
          <ul>
            <li>
              Usługodawca zastrzega sobie prawo do wprowadzania zmian w polityce prywatności. Wszelkie zmiany będą publikowane na stronie serwisu.
            </li>
          </ul>
        </div>

        <p>Data obowiązywania polityki prywatności: 8.03.2025.</p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
