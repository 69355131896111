// Shop.jsx
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import styles from '../css/modules/Shop.module.css';
import Modal from './Modal';
import Login from './Login';
import Register from './Register';

const Shop = () => {
  const { user, userVersion, updateUserFromToken } = useUser();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Przy montowaniu sklepu pobieramy najnowsze dane użytkownika
  useEffect(() => {
    updateUserFromToken().catch(err => console.error('Błąd aktualizacji przy wejściu do sklepu:', err));
  }, [updateUserFromToken]);

  // Logujemy dane, żeby upewnić się, że są aktualne
  useEffect(() => {
    console.log('Shop mount - user:', user, 'userVersion:', userVersion);
  }, [user, userVersion]);

  const userEmail = useMemo(() => {
    return user ? (user.email || user.sub || null) : null;
  }, [user, userVersion]);

  const backgroundDetails = useMemo(() => {
    const bgImagesCount = 5;
    const randomBgNumber = Math.floor(Math.random() * bgImagesCount) + 1;
    const backgroundImageUrl = `/backgrounds/shop/${randomBgNumber}.webp`;
    return {
      backgroundImage: `linear-gradient(135deg, rgba(13, 13, 13, 0.7), rgba(26, 26, 26, 0.7)), url(${backgroundImageUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };
  }, []);

  const getCost = (product) => {
    const match = product.description.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };

  const switchToRegister = () => {
    setIsLoginModalOpen(false);
    setIsRegisterModalOpen(true);
  };

  useEffect(() => {
    if (!API_BASE_URL) {
      console.error('Brak API_BASE_URL');
      setLoading(false);
      return;
    }
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/cyber/get_artefacts_by_level`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ level: 0 }),
        });
        const data = await response.json();
        setProducts(data);
        setLoading(false);
      } catch (error) {
        console.error('Błąd pobierania produktów:', error);
        setLoading(false);
      }
    };
    fetchProducts();
  }, [API_BASE_URL, userVersion]);

  const handleBuy = async (product) => {
    const cost = getCost(product);
    if (!user || (user.score || 0) < cost) return;
    try {
      const response = await fetch(`${API_BASE_URL}/cyber/buy_artifact`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: userEmail,
          artifact_slug: product.slug,
          price: cost,
        }),
      });
      const data = await response.json();
      if (data.error) {
        alert(data.error);
        return;
      }
      alert(data.message);
      // Po zakupie odświeżamy dane użytkownika
      updateUserFromToken().catch(err => console.error('Błąd aktualizacji po zakupie:', err));
    } catch (error) {
      console.error('Błąd podczas zakupu:', error);
      alert('Błąd podczas zakupu artefaktu');
    }
  };

  if (loading) {
    return <div className={styles.cyberpunkContainer}>Ładowanie produktów...</div>;
  }

  return (
    // Klucz oparty na userVersion wymusza remount, gdy dane ulegną zmianie
    <div key={userVersion} className={styles.cyberpunkContainer} style={backgroundDetails}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={() => navigate(-1)}>
          Wróć
        </button>
      </div>
      <h2 className={styles.shopTitle}>Sklep</h2>
      <p className={styles.scoreInfo}>
        {user
          ? `Witaj, ${user.name || 'Gracz'}! Masz: ${user.score} $`
          : 'Aby skorzystać ze sklepu, zaloguj się!'}
      </p>
      <div className={styles.productsContainer}>
        {products.map((product) => {
          const cost = getCost(product);
          const productImageUrl = `/artefacts/${product.slug}.webp`;
          return (
            <div key={product.slug} className={styles.productCard}>
              <img src={productImageUrl} alt={product.name} className={styles.productImage} />
              <h3 className={styles.productName}>{product.name}</h3>
              <p className={styles.productDescription}>{product.description}</p>
              <p className={styles.productCost}>
                <strong>$ {cost}</strong>
              </p>
              {user ? (
                user.score >= cost ? (
                  <button className={styles.buyButton} onClick={() => handleBuy(product)}>
                    Kup
                  </button>
                ) : (
                  <button className={styles.buyButton} disabled>
                    Brak wystarczających punktów
                  </button>
                )
              ) : (
                <button className={styles.buyButton} onClick={() => setIsLoginModalOpen(true)}>
                  Zaloguj się
                </button>
              )}
            </div>
          );
        })}
      </div>
      {user && (
        <div className={styles.artefactsContainer}>
          <h3>Twoje artefakty:</h3>
          <div className={styles.artefactsBox}>
            {Array.from({ length: 4 }, (_, index) => {
              const artifact = user.artefacts && user.artefacts[index];
              return (
                <div key={index} className={styles.artifactSlot}>
                  {artifact ? (
                    <img src={`/artefacts/${artifact}.webp`} alt={`Artefakt ${index + 1}`} className={styles.artifactImage} />
                  ) : (
                    <div className={styles.emptyArtifact} />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <Modal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)}>
        <Login closeModal={() => setIsLoginModalOpen(false)} switchToRegister={switchToRegister} />
      </Modal>
      <Modal isOpen={isRegisterModalOpen} onClose={() => setIsRegisterModalOpen(false)}>
        <Register closeModal={() => setIsRegisterModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default Shop;
