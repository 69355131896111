// src/ExamQuestions6.jsx
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../css/modules/ExamQuestions.module.css';

const ExamQuestions6 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/pytania-egzaminacyjne-testy-patent-strzelecki');
  };

  return (
    <div className={styles.contactContainer}>
      <Helmet>
        <title>Patent strzelecki – amunicja i broń sportowa (część 6)</title>
        <meta
          name="description"
          content="Poznaj pytania egzaminacyjne dotyczące broni sportowej i amunicji w konkurencjach ISSF. Dowiedz się, czym różni się broń bocznego i centralnego zapłonu oraz jakie wymagania musi spełniać sprzęt używany w zawodach."
        />
      </Helmet>

      <div className={styles.content}>
        <h1 className="mainHeader">🎯 Pytania egzaminacyjne – Broń i amunicja sportowa (część 6)</h1>

        <div className="intro">
          📘 Poniższy zestaw obejmuje pytania dotyczące amunicji, parametrów technicznych broni i zasad strzelań sportowych według standardów ISSF.
          Prezentujemy oficjalne pytania egzaminacyjne opublikowane przez Polski Związek Strzelectwa Sportowego. Nasze autorskie opracowanie opiera się na dokumencie źródłowym dostępnym na stronie internetowej PZSS w PDF. To rzetelna podstawa do nauki przed egzaminem.
          Sprawdź nasze testy! Pytania z prawdziwych egzaminów na patent strzelecki. Z nami się zdaje!
        </div>

        <h2>📋 Dane rejestrowe i definicje amunicji</h2>

        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>84. 🧾 Jakie dane muszą znaleźć się w książce rejestru pobytu na strzelnicy?</h3>
            <p>Imię i nazwisko osoby korzystającej, numer pozwolenia na broń oraz organ wydający pozwolenie. Jeśli dana osoba nie ma pozwolenia – jej adres zamieszkania.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>85. 🔘 Czym jest amunicja bocznego zapłonu?</h3>
            <p>To amunicja sportowa, w której zapłon następuje przez uderzenie w bok łuski – najczęściej kaliber .22 LR.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>86. 🎯 Czym jest amunicja centralnego zapłonu?</h3>
            <p>To nowoczesna amunicja, w której spłonka znajduje się centralnie w denku łuski. Używana w broni myśliwskiej, bojowej i sportowej.</p>
          </li>
        </ul>

        <h2>🏅 Amunicja i broń dopuszczona przez ISSF</h2>

        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>87–88. 💡 Jaką amunicję dopuszcza ISSF do zawodów?</h3>
            <p>Amunicja bocznego zapłonu o kalibrze 5,6 mm (.22" long rifle), wykonana z miękkiego ołowiu lub podobnych materiałów. Stosowana w konkurencjach karabinowych i pistoletowych.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>89. 🔢 Która broń ma największy kaliber wagomiarowy?</h3>
            <p>Broń o kalibrze 12 lub większym. W kalibrach wagomiarowych niższy numer oznacza większą średnicę przewodu lufy.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>90. 💨 Jaką broń pneumatyczną dopuszcza ISSF?</h3>
            <h4>Pistolety:</h4>
            <p>Do 1500 g, z oporem języka spustowego min. 500 g, wymiary nieprzekraczające 420×200×50 mm.</p>
            <h4>Karabiny:</h4>
            <p>Do 5500 g, długość celownika do 850 mm, dowolny typ mechanizmu spustowego.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>91. 🎯 Jaką broń palną bocznego zapłonu dopuszcza ISSF?</h3>
            <p>Pistolety i rewolwery kal. .22 (5,6 mm), karabinki o długości lufy do 762 mm i masie do 8000 g – używane w zawodach dla juniorów i dorosłych.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>92. 🟡 Czy broń śrutowa może być używana w ISSF?</h3>
            <p>Tak – broń gładkolufowa o kalibrze nie większym niż 12, z komorą nabojową do 76 mm. Używana w konkurencjach trap i skeet.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>93. 🔫 Jaką broń centralnego zapłonu dopuszcza ISSF?</h3>
            <h4>Pistolety i rewolwery:</h4>
            <p>Kaliber od 7,62 mm (.30") do 9,65 mm (.38"), masa max 1400 g, długość lufy do 153 mm.</p>
            <h4>Karabiny:</h4>
            <p>Kaliber do 8 mm (.315"), ciężar do 8000 g.</p>
          </li>
        </ul>

        <h2>🚫 Amunicja niedozwolona przez ISSF</h2>

        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>94. ❌ Jakiej amunicji nie wolno stosować w zawodach ISSF?</h3>
            <p>Niepełna treść pytania – ale na podstawie przepisów ISSF zabroniona jest amunicja z rdzeniami stalowymi, pociskami przeciwpancernymi, zapalającymi i wybuchowymi. Niedozwolone są też naboje o mocy przekraczającej określone limity energii.</p>
          </li>
        </ul>

        <div className="buttonContainer">
          <button onClick={handleNext} className={styles.secondaryButton}>
            Kolejne pytania z odp
          </button>
          <button onClick={() => navigate('/')} className={styles.primaryButton}>
           Testy egzaminacyjne - patent strzelecki
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamQuestions6;
