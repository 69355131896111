import React, { useState } from 'react';
import styles from '../css/modules/CartIcon.module.css';
import CartModal from './CartModal';

const CartIcon = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleIconClick = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div className={styles.cartIcon} onClick={handleIconClick}>
        <img src="/icons/cart.svg" alt="Koszyk na zakupy" />
      </div>
      <CartModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default CartIcon;
