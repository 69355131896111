// src/QuizContent.jsx
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import styles from '../css/modules/Quiz.module.css';
import TimerBar from './TimerBar';
import UserWindow from './UserWindow';
import EnemyWindow from './EnemyWindow';
import Typewriter from './Typewriter';
import Answers from './Answers';
import BottomBarDriving from './BottomBarDriving';

const QuestionBlock = React.memo(({
  currentQuestion,
  onTypingEnd,
  isQuestionFinished,
  areAnswersFinished,
  selectedAnswers,
  isChecked,
  onToggleAnswer,
  onAnswersAnimationEnd,
  onPrevious,
  onAttack,
  onNext,
  explanationVisible
}) => {
  const answerKeys = ['answerA', 'answerB', 'answerC'];
  return (
    <>
      <p className={styles.examDate}>
        {/* Opcjonalnie: data lub dodatkowe informacje */}
      </p>
      <h3>
        <Typewriter 
          text={currentQuestion.question}
          typingSpeed={10}
          onTypingEnd={onTypingEnd}
        />
      </h3>
      {isQuestionFinished && (
        <Answers
          answerKeys={answerKeys}
          currentQuestion={currentQuestion}
          selectedAnswers={selectedAnswers}
          isChecked={isChecked}
          toggleAnswerSelection={onToggleAnswer}
          initialDelay={200}
          appearanceDelay={300}
          onAnimationEnd={onAnswersAnimationEnd}
        />
      )}
      {areAnswersFinished && (
        <>
          <BottomBarDriving 
            currentQuestionIndex={currentQuestion.number - 1}
            questionsLength={currentQuestion.totalQuestions || 0}
            onNext={onNext}
            onToggleExplanation={currentQuestion.onToggleExplanation}
            explanationVisible={explanationVisible}
          />
          {explanationVisible && currentQuestion.explanation && (
            <div className={styles.explanation}>
              <ReactMarkdown>{currentQuestion.explanation}</ReactMarkdown>
            </div>
          )}
        </>
      )}
    </>
  );
});

const QuizContent = ({
  userData,
  enemyData,
  currentQuestion,
  selectedAnswers,
  isChecked,
  onToggleAnswer,
  timeLeft,
  isQuestionFinished,
  areAnswersFinished,
  onTypingEnd,
  onAnswersAnimationEnd,
  onPrevious,
  onAttack,
  onNext,
  explanationVisible,
  onToggleExplanation,
  userKey
}) => {
  // Losujemy tło tylko raz przy montowaniu komponentu
  const backgroundImageUrl = useMemo(() => {
    const bgImagesCount = Number(process.env.REACT_APP_BACKGROUND_IMAGES_SIZE);
    const randomBgNumber = Math.floor(Math.random() * bgImagesCount) + 1;
    return `/backgrounds/${randomBgNumber}.webp`;
  }, []);

  // Ustalamy styl tła z gradientem oraz losowym obrazem
  const containerStyle = {
    backgroundImage: `linear-gradient(135deg, rgba(13, 13, 13, 0.7), rgba(26, 26, 26, 0.7)), url(${backgroundImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.header}>
        <Link 
          to="/shop" 
          state={{ score: userData.score }} 
          className={styles.shopButton}
        >
          Sklep
        </Link>
      </div>
      <div className={styles.duelContainerTop}>
        <UserWindow 
          image={userData.image}
          name={userData.name}
          energy={userData.energy}
          maxEnergy={userData.maxEnergy}
          score={userData.score}
        />
        <div className={styles.vs}>VS</div>
        <EnemyWindow enemy={enemyData} />
      </div>
      <div className={styles.questionSection}>
        <TimerBar 
          timeLeft={timeLeft} 
          maxTime={enemyData.time || 15} 
          active={!isChecked} 
        />
        <QuestionBlock
          currentQuestion={{...currentQuestion, onToggleExplanation}}  // Przekazujemy onToggleExplanation do pytania
          onTypingEnd={onTypingEnd}
          isQuestionFinished={isQuestionFinished}
          areAnswersFinished={areAnswersFinished}
          selectedAnswers={selectedAnswers}
          isChecked={isChecked}
          onToggleAnswer={onToggleAnswer}
          onAnswersAnimationEnd={onAnswersAnimationEnd}
          onPrevious={onPrevious}
          onAttack={onAttack}
          onNext={onNext}
          explanationVisible={explanationVisible}
        />
      </div>
    </div>
  );
};

export default QuizContent;
