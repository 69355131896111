// src/ThankYou.jsx
import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styles from '../css/modules/ThankYou.module.css';
import Typewriter from './Typewriter';
import { useUser } from './UserContext';

const defaultUserData = {
  name: 'Użytkowniku',
};

const ThankYou = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const finalUserData = user || defaultUserData;
  const finalUserName = finalUserData.name || 'Użytkowniku';

  // Losowanie tła na podstawie liczby dostępnych obrazów
  const bgImagesCount = Number(process.env.REACT_APP_BACKGROUND_IMAGES_SIZE) || 5;
  const randomBgNumber = Math.floor(Math.random() * bgImagesCount) + 1;
  const backgroundImageUrl = `/backgrounds/${randomBgNumber}.webp`;

  const containerStyle = {
    backgroundImage: `linear-gradient(135deg, rgba(13, 13, 13, 0.7), rgba(26, 26, 26, 0.7)), url(${backgroundImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  // Wysyłanie zdarzenia konwersji przy montowaniu komponentu
  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-16960208448/5-_iCLe9grEaEMD8oJc_',
        'value': 1.0,
        'currency': 'PLN'
      });
    }
  }, []);

  // Przekierowanie – kontynuacja nauki lub powrót do sklepu.
  const handleContinue = () => {
    navigate('/intro', { state: { userData: finalUserData } });
  };

  return (
    <div className={styles.thankYouContainer} style={containerStyle}>
      <h1 className={styles.thankYouMessage}>
        <Typewriter 
          text={`Dziękujemy za zakup dostępu do testów na patent strzelecki!`} 
          typingSpeed={100} 
        />
      </h1>
      <p className={styles.subMessage}>
        Zespół Strzelasz.pl życzy Ci przyjemnej nauki i zdania egzaminu na patent strzelecki!
      </p>
      <p className={styles.subMessage}>
        Niech nauka będzie wciągająca jak gra w cyberpunkowym świecie!
      </p>
      <p className={styles.loginReminder}>
        Pamiętaj, żeby  <b>zalogować się </b> zanim zaczniesz korzystać z wykupionych testów! (prawy górny róg)
      </p>
      <div className={styles.buttonContainer}>
        <button 
          className={styles.thankYouButton} 
          onClick={handleContinue}
        >
          START
        </button>
      </div>
    </div>
  );
};

export default ThankYou;
