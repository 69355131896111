import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../css/modules/Footer.module.css';

function Footer() {
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footerLinks}>
          {/* Inne linki lub zawartość */}
        </div>  
        {/* <div className="footer-contact">kontakt@egzaminrzeczoznawcy.pl</div> */}
        <div className={styles.footerLinks}>
          <Link to="/regulamin" rel="nofollow">
            Regulamin
          </Link>
          <Link to="/polityka-prywatnosci" rel="nofollow">
            Polityka prywatności
          </Link>
          <Link to="/pytania-na-pozwolenie-na-bron-patent-strzelecki">
            Pytania
          </Link>   
          <Link to="/kontakt">
            Kontakt
          </Link>   
        </div>
        <div> 
          {/* Dodatkowa zawartość */}
        </div>
      </div>
      {/* <BottomLinksColumns /> */}
    </>
  );
}

export default Footer;
