import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../components/UserContext'; // Dopasuj ścieżkę do swojego UserContext
import RegistrationForm from './RegistrationForm'; // Dopasuj ścieżkę, gdzie zapisałeś RegistrationForm.jsx
import styles from '../css/modules/PurchaseRegistration.module.css';

const PurchaseRegistration = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [plan, setPlan] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  // Stan dla kodu rabatowego oraz informacji o rabacie
  const [discountCode, setDiscountCode] = useState('');
  const [discountInfo, setDiscountInfo] = useState(null);
  const [isDiscountLocked, setIsDiscountLocked] = useState(false);

  // Checkboxy – widoczne tylko przy rejestracji
  const [selectAll, setSelectAll] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptMarketing, setAcceptMarketing] = useState(false);
  const [acceptPartners, setAcceptPartners] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Pobieramy dane użytkownika z kontekstu
  const { user } = useUser();
  const isLoggedIn = !!user; // Użytkownik jest zalogowany, gdy obiekt user istnieje

  // Jeżeli użytkownik jest zalogowany, ustawiamy email z kontekstu
  useEffect(() => {
    if (isLoggedIn && user.email) {
      setEmail(user.email);
    }
  }, [isLoggedIn, user]);

  // Pobranie parametru "days" z URL, który określa wybrany plan
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlDays = params.get('days');
    if (urlDays && ['10', '30', '90'].includes(urlDays)) {
      setPlan(urlDays);
    }
  }, [location.search]);

  // Logika checkboxów – dotyczy tylko rejestracji
  useEffect(() => {
    if (!isLoggedIn) {
      setAcceptTerms(selectAll);
      setAcceptMarketing(selectAll);
      setAcceptPartners(selectAll);
    }
  }, [selectAll, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) {
      if (acceptTerms && acceptMarketing && acceptPartners) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
  }, [acceptTerms, acceptMarketing, acceptPartners, isLoggedIn]);

  // Funkcja generująca dynamiczny sessionId
  const generateSessionId = () => {
    return 'sesja' + Date.now() + '_' + Math.random().toString(36).substr(2, 9);
  };

  // Funkcja pobierająca cenę bazową z pliku .env
  const getBasePrice = (selectedPlan) => {
    const basePrices = {
      '10': parseFloat(process.env.REACT_APP_PRICE_10) || 0,
      '30': parseFloat(process.env.REACT_APP_PRICE_30) || 0,
      '90': parseFloat(process.env.REACT_APP_PRICE_90) || 0,
    };
    return basePrices[selectedPlan] || 0;
  };

  // Wyliczenie finalnej ceny z uwzględnieniem ewentualnego rabatu
  const getFinalPrice = () => {
    const basePrice = getBasePrice(plan);
    if (discountInfo && discountInfo.discountPercentage) {
      return (basePrice - (basePrice * discountInfo.discountPercentage) / 100).toFixed(2);
    }
    return basePrice.toFixed(2);
  };

  // Obsługa sprawdzania kodu rabatowego
  const handleDiscountCheck = async (e) => {
    e.preventDefault();
    setIsError(false);
    setMessage('');
    try {
      const response = await axios.get(`${API_BASE_URL}/cyber/check-discount?code=${discountCode}`);
      if (response.data.success) {
        setDiscountInfo(response.data);
        setMessage(`Kod rabatowy zaakceptowany. Zniżka: ${response.data.discountPercentage}%`);
        // Blokujemy edycję pola discountCode oraz wyłączamy guzik
        setIsDiscountLocked(true);
      } else {
        setDiscountInfo(null);
        setMessage('Niepoprawny lub wygasły kod rabatowy.');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Błąd podczas sprawdzania kodu rabatowego.');
      setIsError(true);
    }
  };

  // Obsługa wysyłania formularza, rejestracji (dla niezalogowanych) oraz inicjacji płatności
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsError(false);
    setMessage('');

    if (!plan) {
      setMessage('Proszę wybrać plan dostępu.');
      setIsError(true);
      return;
    }

    // Jeśli użytkownik nie jest zalogowany – przeprowadzamy rejestrację
    if (!isLoggedIn) {
      if (password.length < 4) {
        setMessage('Hasło musi mieć co najmniej 4 znaki.');
        setIsError(true);
        return;
      }
      if (password !== confirmPassword) {
        setMessage('Hasła nie są takie same.');
        setIsError(true);
        return;
      }
      if (!acceptTerms) {
        setMessage('Musisz zaakceptować regulamin.');
        setIsError(true);
        return;
      }
      
      try {
        const regResponse = await axios.post(`${API_BASE_URL}/cyber/register`, {
          name,
          email,
          password,
          promo_notifications: acceptMarketing,
          acceptPartners: acceptPartners
        });
        if (regResponse.data.error) {
          setMessage(regResponse.data.error);
          setIsError(true);
          return;
        }
        if (regResponse.data.token) {
          localStorage.setItem('access_token', regResponse.data.token);
        }
      } catch (error) {
        setMessage('Błąd rejestracji.');
        setIsError(true);
        return;
      }
    }

    // Generujemy sessionId
    const sessionId = generateSessionId();

    // Przygotowanie danych do płatności – przekazujemy discountCode jako null, jeśli nie został podany
    const paymentData = {
      sessionId: sessionId,
      amount: parseFloat(getFinalPrice()),
      email: email,
      plan: plan, 
      description: "Testy na pozwolenie na broń",
      discountCode: discountCode.trim() === '' ? null : discountCode
    };

    try {
      const response = await axios.post('https://api.cyberpunky.net/api/init_payment', paymentData);
      if (response.data.status === "success" && response.data.payment_url) {
        window.location.href = response.data.payment_url;
      } else {
        setMessage("Błąd inicjalizacji płatności.");
        setIsError(true);
      }
    } catch (error) {
      const errorMsg =
        error.response &&
        (error.response.data.error || error.response.data.message)
          ? error.response.data.error || error.response.data.message
          : 'Błąd płatności';
      setMessage(errorMsg);
      setIsError(true);
    }
  };

  return (
    <div className={styles.container}>
      <video 
        autoPlay 
        muted 
        loop 
        playsInline 
        className={styles.backgroundVideo}
      >
        <source src="/backgrounds/why_cyberdriver_video_small.mp4" type="video/mp4" />
        Twoja przeglądarka nie obsługuje odtwarzania wideo.
      </video>
      <div className={styles.overlay}></div>
      <div className={styles.content}>
        <h1>{isLoggedIn ? 'Zakup Dostępu' : 'Rejestracja i Zakup'}</h1>
        <form onSubmit={handleSubmit}>
          {/* Jeśli użytkownik nie jest zalogowany, renderujemy formularz rejestracyjny */}
          {!isLoggedIn && (
            <RegistrationForm
              name={name}
              setName={setName}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              acceptTerms={acceptTerms}
              setAcceptTerms={setAcceptTerms}
              acceptMarketing={acceptMarketing}
              setAcceptMarketing={setAcceptMarketing}
              acceptPartners={acceptPartners}
              setAcceptPartners={setAcceptPartners}
            />
          )}
          <div>
            <label htmlFor="plan">Wybierz plan dostępu:</label>
            <select 
              id="plan" 
              value={plan} 
              onChange={(e) => setPlan(e.target.value)}
              required
            >
              <option value="" disabled>
                -- Wybierz --
              </option>
              <option value="10">10 dni</option>
              <option value="30">30 dni</option>
              <option value="90">90 dni</option>
            </select>
          </div>
          {plan && (
            <div className={styles.priceSummary}>
              <p>Kwota do zapłaty: {getFinalPrice()} zł</p>
            </div>
          )}
          <div className={styles.discountContainer}>
            <input
              type="text"
              id="discountCode"
              placeholder="Kod rabatowy"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
              readOnly={isDiscountLocked}
            />
            <button 
              onClick={handleDiscountCheck}
              disabled={isDiscountLocked || !discountCode.trim()}
              className={styles.discountButton}
            >
              Sprawdź
            </button>
          </div>
          <button className={styles.payButton} type="submit">
            ZAPŁAĆ
          </button>
          {message && (
            <p className={isError ? styles.errorMessage : ''}>
              {message}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default PurchaseRegistration;
