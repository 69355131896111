import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../css/modules/Rating.module.css';

const Rating = () => {
  const navigate = useNavigate();
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [ranking, setRanking] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const WEBSITE = process.env.REACT_APP_WEBSITE;

  // Losowanie tła przy montowaniu komponentu
  useEffect(() => {
    const bgImagesCount = Number(process.env.REACT_APP_BACKGROUND_IMAGES_SIZE) || 5;
    const randomBgNumber = Math.floor(Math.random() * bgImagesCount) + 1;
    setBackgroundImageUrl(`/backgrounds/${randomBgNumber}.webp`);
  }, []);

  // Pobieranie rankingu
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/cyber/get_ranking?website=${WEBSITE}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error fetching ranking');
        }
        return response.json();
      })
      .then((data) => {
        setRanking(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  // Ustawienie stylu tła: pełna szerokość, pełna wysokość, ciemne tło z gradientem
  const containerStyle = {
    // backgroundImage: `linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5)), url(${backgroundImageUrl})`,
    backgroundColor: 'var(--bacground-color)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100vw',
    minHeight: '100vh',
    position: 'relative',
  };

  if (loading)
    return (
      <div className={styles.rating} style={containerStyle}>
        Loading ranking...
      </div>
    );
  if (error)
    return (
      <div className={styles.rating} style={containerStyle}>
        Error: {error}
      </div>
    );

  return (
    <div className={styles.rating} style={containerStyle}>
      {/* Guzik Wróć */}
      <button className={styles.backButton} onClick={() => navigate(-1)}>
        Wróć
      </button>
      <div className={styles.content}>
      <h1 className={styles.header}>TOP 30</h1>
        <h2 className={styles.header}>Lista najlepszych</h2>
        <h3 className={styles.subheader}>wg liczby dobrze rozwiązanych pytań</h3>
        <ul className={styles.rankingList}>
          {ranking.map((user, index) => (
            <li key={user.slug || index} className={styles.rankingItem}>
              <span className={styles.rankPosition}>{index + 1}.</span>
              <span className={styles.userName}>{user.name}</span>
              <span className={styles.userCorrect}>{user.answered_correct}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Rating;
