import React from 'react';
import { useUser } from './UserContext'; // dostosuj ścieżkę do miejsca, gdzie znajduje się Twój kontekst

const UserProfile = () => {
  const { user } = useUser();

  if (!user) {
    return <div>Brak danych użytkownika.</div>;
  }

  // Obsługa daty końca dostępu do testów
  let accessInfo = null;
  if (user.test_access_till) {
    const accessEndDate = new Date(user.test_access_till);
    const now = new Date();
    const diffTime = accessEndDate - now;
    // Obliczamy liczbę dni, zaokrąglając w górę
    const daysLeft = diffTime > 0 ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) : 0;
    // Formatowanie daty – możesz zmienić format wg własnych potrzeb
    const formattedDate = accessEndDate.toLocaleString();

    accessInfo = (
      <div>
        <p>
          <strong>Dostęp do testów do:</strong> {formattedDate}
        </p>
        <p>
          <strong>Pozostało dni:</strong> {daysLeft}
        </p>
      </div>
    );
  }

  return (
    <div>
      <p><strong>Użytkownik:</strong> {user.name}</p>
      <p><strong>Poziom:</strong> {user.level}</p>

      {accessInfo}

      <div>
        <h3>Statystyki rozwiązań pytań</h3>
        <p>
          <strong>Odpowiedzi poprawne:</strong> {user.answered_correct}
        </p>
        <p>
          <strong>Odpowiedzi niepoprawne:</strong> {user.answered_wrong}
        </p>
      </div>
    </div>
  );
};

export default UserProfile;
