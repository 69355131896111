import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode'; // Używamy nazwanej funkcji jwtDecode
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userVersion, setUserVersion] = useState(0);
  const location = useLocation();

  const updateUserFromToken = useCallback(async () => {
    const token = localStorage.getItem('access_token');
    if (token) {
      try {
        const response = await axios.get(`${API_BASE_URL}/cyber/user_info?ts=${Date.now()}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            // Nie wysyłamy dodatkowych nagłówków, aby uniknąć problemów CORS
          },
        });
        const data = response.data;
        // Używamy jwtDecode, który jest nazwanym eksportem
        const decoded = jwtDecode(token);

        // Jeśli nie ma email, ustawiamy domyślny email (możesz dostosować tę logikę)
        const emailFromToken = decoded.email || decoded.sub || "unknown@example.com";

        const userData = {
          ...decoded,
          email: emailFromToken,
          score: data.score ?? decoded.score ?? 0,
          energy: data.energy ?? decoded.energy ?? 100,
          level: data.level ?? decoded.level ?? 0,
          artefacts: data.artefacts ?? decoded.artefacts ?? [],
          image: data.image ?? decoded.image ?? 'my-photo.webp',
          name: data.name ?? decoded.name ?? 'Gracz',
          answered_correct: data.answered_correct ?? 0,
          answered_wrong: data.answered_wrong ?? 0,
          categories: data.categories ?? decoded.categories ?? [],
          test_access_till: data.test_access_till
        };

        setUser({ ...userData });
        setUserVersion(prev => prev + 1);
      } catch (error) {
        console.error('Błąd pobierania danych użytkownika:', error);
        setUser(null);
      }
    } else {
      setUser(null);
    }
  }, [API_BASE_URL]);

  useEffect(() => {
    updateUserFromToken();
  }, [location, updateUserFromToken]);

  const login = useCallback((token) => {
    localStorage.setItem('access_token', token);
    updateUserFromToken();
  }, [updateUserFromToken]);

  const logout = useCallback(() => {
    localStorage.removeItem('access_token');
    setUser(null);
  }, []);

  const enhancedSetUser = useCallback((updater) => {
    setUser(prevUser => {
      const newUser = typeof updater === 'function'
        ? updater(prevUser)
        : { ...prevUser, ...updater };
      setUserVersion(prev => prev + 1);
      return { ...prevUser, ...newUser };
    });
  }, []);

  return (
    <UserContext.Provider value={{
      user,
      login,
      logout,
      setUser: enhancedSetUser,
      updateUserFromToken,
      userVersion,
    }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext };
