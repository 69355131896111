import React from 'react';
import { Helmet } from 'react-helmet';

function Regulamin() {
  return (
    <>
      <Helmet>
        <title>Regulamin serwisu strzelasz.pl</title>
        <meta charSet="UTF-8" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="article-container">
        <h1>Regulamin serwisu strzelasz.pl</h1>

        <div className="section">
          <h2>I. Postanowienia ogólne</h2>
          <ul>
            <li>
              Niniejszy regulamin określa zasady korzystania z serwisu internetowego dostępnego pod adresem{' '}
              <a href="http://strzelasz.pl">strzelasz.pl</a>, którego właścicielem jest firma Magdalena Pilch z siedzibą w Krakowie, ul. Lubicz 17, 31-075 Kraków, zwana dalej „Usługodawcą”.
            </li>
            <li>
              Kontakt z Usługodawcą jest możliwy poprzez adres e-mail: kontakt@strzelasz.pl
            </li>
            <li>
              Korzystanie z serwisu oznacza akceptację niniejszego regulaminu. Użytkownik przed wykupieniem dostępu do testów zobowiązany jest do zapoznania się z jego treścią.
            </li>
          </ul>
        </div>

        <div className="section">
          <h2>II. Zakres usług</h2>
          <ul>
            <li>Serwis umożliwia dostęp do testów na patent strzelecki za pośrednictwem internetu po wykupieniu odpowiedniego pakietu.</li>
            <li>Testy dostępne w serwisie są zgodne z aktualnymi przepisami prawa o ruchu drogowym w Polsce.</li>
            <li>Usługodawca zastrzega sobie prawo do aktualizacji oraz modyfikacji treści testów w celu dostosowania ich do zmieniających się przepisów.</li>
          </ul>
        </div>

        <div className="section">
          <h2>III. Rejestracja i konto użytkownika</h2>
          <ul>
            <li>Korzystanie z serwisu wymaga rejestracji i założenia konta użytkownika.</li>
            <li>Użytkownik zobowiązany jest do podania prawdziwych danych podczas rejestracji.</li>
            <li>Zabrania się udostępniania konta osobom trzecim.</li>
          </ul>
        </div>

        <div className="section">
          <h2>IV. Warunki zakupu i płatności</h2>
          <ul>
            <li>Dostęp do testów jest płatny zgodnie z cennikiem dostępnym na stronie serwisu.</li>
            <li>Płatności mogą być dokonywane za pomocą metod wskazanych w serwisie, takich jak przelew bankowy, karta płatnicza lub inne dostępne formy płatności.</li>
            <li>Usługodawca wystawia faktury na życzenie użytkownika po uprzednim podaniu danych niezbędnych do ich wystawienia.</li>
          </ul>
        </div>

        <div className="section">
          <h2>V. Realizacja usługi</h2>
          <ul>
            <li>Dostęp do testów zostaje aktywowany natychmiast po zaksięgowaniu płatności.</li>
            <li>Usługa jest świadczona przez okres wykupionego pakietu zgodnie z ofertą zamieszczoną na stronie.</li>
            <li>Użytkownik ma możliwość korzystania z testów w sposób nielimitowany w ramach wykupionego pakietu.</li>
          </ul>
        </div>

        <div className="section">
          <h2>VI. Odstąpienie od umowy i reklamacje</h2>
          <ul>
            <li>
              Użytkownik będący konsumentem ma prawo do odstąpienia od umowy w ciągu 14 dni od daty zakupu bez podania przyczyny, chyba że rozpoczął korzystanie z testów za jego wyraźną zgodą przed upływem tego terminu.
            </li>
            <li>
              Reklamacje dotyczące działania serwisu należy zgłaszać na adres e-mail: kontakt@strzelasz.pl. Usługodawca rozpatruje reklamacje w ciągu 14 dni roboczych.
            </li>
          </ul>
        </div>

        <div className="section">
          <h2>VII. Odpowiedzialność</h2>
          <ul>
            <li>Usługodawca nie ponosi odpowiedzialności za problemy techniczne leżące po stronie użytkownika, które uniemożliwiają korzystanie z serwisu.</li>
            <li>Usługodawca dokłada wszelkich starań, aby serwis działał bez zakłóceń i był dostępny przez całą dobę.</li>
          </ul>
        </div>

        <div className="section">
          <h2>VIII. Dane osobowe i polityka prywatności</h2>
          <ul>
            <li>Administratorem danych osobowych użytkowników jest Magdalena Pilch z siedzibą w Krakowie, ul. Lubicz 17, 31-075 Kraków.</li>
            <li>Dane osobowe użytkowników są przetwarzane zgodnie z obowiązującymi przepisami prawa oraz polityką prywatności dostępną na stronie serwisu.</li>
            <li>Użytkownik ma prawo do wglądu, poprawiania oraz żądania usunięcia swoich danych osobowych.</li>
          </ul>
        </div>

        <div className="section">
          <h2>IX. Zmiany regulaminu</h2>
          <ul>
            <li>Usługodawca zastrzega sobie prawo do zmiany niniejszego regulaminu. Informacja o zmianach zostanie opublikowana na stronie serwisu.</li>
            <li>Zmiany wchodzą w życie po upływie 7 dni od ich publikacji. Korzystanie z serwisu po tym terminie oznacza akceptację nowych postanowień regulaminu.</li>
          </ul>
        </div>

        <div className="section">
          <h2>X. Postanowienia końcowe</h2>
          <ul>
            <li>W sprawach nieuregulowanych niniejszym regulaminem zastosowanie mają przepisy prawa polskiego.</li>
            <li>Ewentualne spory wynikające z korzystania z serwisu będą rozstrzygane przez właściwy sąd powszechny zgodnie z siedzibą Usługodawcy.</li>
          </ul>
        </div>

        <p>Data obowiązywania regulaminu: 8.03.2025.</p>
      </div>
    </>
  );
}

export default Regulamin;
