// src/ExamQuestions8.jsx
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../css/modules/ExamQuestions.module.css';

const ExamQuestions8 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/testy-patent-strzelecki-pytania-i-opowiedzi-z-pzss');
  };

  return (
    <div className={styles.contactContainer}>
      <Helmet>
        <title>Patent strzelecki – konkurencje ISSF i przepisy karne (część 8)</title>
        <meta
          name="description"
          content="Zestaw pytań egzaminacyjnych obejmujący konkurencje ISSF, postawy strzeleckie, odległości i przepisy karne związane z bronią. Sprawdź swoją wiedzę przed egzaminem!"
        />
      </Helmet>

      <div className={styles.content}>
        <h1 className="mainHeader">🎯 Pytania egzaminacyjne – konkurencje ISSF i przepisy karne (część 8)</h1>

        <div className="intro">
          📘 Sprawdź, jakie konkurencje obowiązują na zawodach, jakie postawy są dopuszczalne oraz jakie przepisy prawa karnego dotyczą broni.
          To właśnie te pytania – prosto z dokumentu opublikowanego przez PZSS – sprawdzają Twoją wiedzę na egzaminie. Nasze opracowanie pomoże Ci ogarnąć całość sprawnie i skutecznie.
          🎯 Wejdź na poziom egzaminatora – zacznij trening z naszymi testami w formie cyberpunkowej gry!
        </div>

        <h2>🎯 Konkurencje strzeleckie i postawy</h2>

        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>111. 🟠 Jakie konkurencje należą do strzelby gładkolufowej?</h3>
            <p>Skeet, trap, double trap i automatic trap – to dyscypliny polegające na strzelaniu do rzutków z różnych odległości i kątów.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>112. 📥 Kiedy można załadować magazynek podczas zawodów na 25 m?</h3>
            <p>Po komendzie „ŁADUJ” wydanej przez prowadzącego strzelanie.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>113. 📏 Jakie są odległości w konkurencjach pistoletowych ISSF?</h3>
            <p>10 m, 25 m i 50 m – w zależności od rodzaju konkurencji i klasy zawodnika.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>114. 📐 Jakie są odległości w konkurencjach karabinowych ISSF?</h3>
            <p>10 m, 50 m i 300 m – odległość 300 m dotyczy zawodów karabinowych wyższego szczebla.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>115. 🎯 Jakie odległości obowiązują w konkurencjach ISSF?</h3>
            <p>10 / 25 / 50 / 300 metrów – obejmują zarówno pistolet, karabin, jak i ruchome cele.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>116. 🎯 Na jaką odległość strzela się w konkurencji pistolet sportowy 30+30?</h3>
            <p>25 metrów – zawodnik oddaje łącznie 60 strzałów, w dwóch seriach po 30.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>117. 🧠 Jak wygląda konkurencja pistolet szybkostrzelny?</h3>
            <p>Strzelanie do pięciu tarcz ustawionych w odległości 25 metrów – liczy się refleks, precyzja i czas.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>118. 💨 Jakie konkurencje odbywają się na 10 metrów?</h3>
            <p>Pistolet pneumatyczny i karabin pneumatyczny – w pozycji stojącej.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>119. 🎯 Jakie konkurencje odbywają się na 25 metrów?</h3>
            <p>Pistolet sportowy, szybkostrzelny i centralnego zapłonu.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>120. 🎯 Jakie konkurencje odbywają się na 50 metrów?</h3>
            <p>Karabin sportowy, karabin dowolny, karabin do ruchomej tarczy.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>121. 🧍 Jakie są postawy w konkurencjach karabinowych?</h3>
            <p>Leżąca, stojąca i klęcząca – zawodnik może rywalizować w każdej z nich lub w trójboju.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>122. 🏅 Jakie są dyscypliny strzelectwa sportowego?</h3>
            <p>Pistolet, karabin i strzelba gładkolufowa – zgodnie z klasyfikacją ISSF.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>123. 🔫 Jakie typy pistoletów dopuszcza ISSF?</h3>
            <p>Pistolet dowolny, sportowy, centralnego zapłonu, szybkostrzelny oraz pneumatyczny.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>124. 🟡 Na czym polega konkurencja SKEET?</h3>
            <p>Strzelanie do rzutków wyrzucanych z dwóch wież – wysokiej i niskiej, przy zmiennych trajektoriach.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>125. 🟠 Na czym polega konkurencja TRAP?</h3>
            <p>Strzelanie do rzutków wyrzucanych z bunkra (schronu) położonego 15 metrów przed linią strzału.</p>
          </li>
        </ul>

        <h2>⚖️ Przepisy karne dotyczące broni</h2>
        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>126. 🚨 Jakie kary stosuje się za naruszenie regulaminu ISSF?</h3>
            <p>Ostrzeżenie, odjęcie punktów lub dyskwalifikacja – zależnie od rodzaju przewinienia.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>127. 🍺 Co grozi za noszenie broni po spożyciu alkoholu lub środków psychoaktywnych?</h3>
            <p>Areszt lub grzywna – zgodnie z ustawą o broni i amunicji.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>128. ❌ Co grozi za porzucenie broni lub amunicji?</h3>
            <p>Kara ograniczenia wolności lub pozbawienia wolności do lat 2 – broń musi być pod stałym nadzorem.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>129. 📋 Co grozi za brak rejestracji lub złożenia broni?</h3>
            <p>Kara aresztu lub grzywny – obowiązki te wynikają wprost z ustawy o broni.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>130. 🔫 Co grozi za posiadanie lub zbycie broni pneumatycznej bez rejestracji?</h3>
            <p>Kara aresztu lub grzywny – dotyczy też nieuprawnionego posiadania gazów obezwładniających lub innych niebezpiecznych urządzeń.</p>
          </li>
        </ul>

        <div className="buttonContainer">
          <button onClick={handleNext} className={styles.secondaryButton}>
            👉 Przejdź do kolejnych pytań
          </button>
          <button onClick={() => navigate('/')} className={styles.primaryButton}>
            🧠 Sprawdź testy
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamQuestions8;
