// src/ExamQuestions5.jsx
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../css/modules/ExamQuestions.module.css';

const ExamQuestions5 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/testy-patent-strzelecki');
  };

  return (
    <div className={styles.contactContainer}>
      <Helmet>
        <title>Patent strzelecki – pytania ze strzelnicy (część 5)</title>
        <meta
          name="description"
          content="Zbiór pytań egzaminacyjnych dotyczących zasad bezpieczeństwa i procedur obowiązujących na strzelnicy. Dowiedz się, jak zachować się podczas strzelania."
        />
      </Helmet>

      <div className={styles.content}>
        <h1 className="mainHeader">🎯 Pytania egzaminacyjne – Zasady zachowania na strzelnicy (część 5)</h1>

        <div className="intro">
          📘 Sprawdź, jak poprawnie zachowywać się na strzelnicy – od wydawania komend po bezpieczeństwo osób i broni.
          Wiesz już, że przed Tobą egzamin na patent strzelecki? Dobrze, że tu jesteś! Poniższe pytania pochodzą prosto z oficjalnej listy opublikowanej przez Polski Związek Strzelectwa Sportowego. My przygotowaliśmy ich przystępne, autorskie opracowanie – żebyś mógł uczyć się szybciej, łatwiej i skuteczniej.
          Pamiętaj skorzystać z naszych testów! Gdy je przerobisz, gwarantujemy, że zdasz egzamin! Zabawa + wiedza = pozwolenie na broń.
        </div>

        <h2>📢 Komendy i procedury strzeleckie</h2>
        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>66. 🛑 Kto może wydać komendę „STOP”?</h3>
            <p>Każda osoba obecna na strzelnicy, jeśli uzna, że istnieje zagrożenie. Komenda „STOP” oznacza natychmiastowe przerwanie strzelania przez wszystkich uczestników.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>67. 🐶 Czy zwierzęta są dozwolone na strzelnicy?</h3>
            <p>Tylko pod warunkiem, że są trzymane na uwięzi i pod stałym nadzorem opiekuna, aby nie stwarzały zagrożenia dla uczestników.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>68. 🔃 Co należy zrobić po komendzie „ROZŁADUJ”?</h3>
            <p>Rozładować broń, zabezpieczyć ją i odłożyć na stanowisko strzeleckie. Lufa powinna być skierowana w bezpiecznym kierunku.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>69. 🚫 Czy można odkładać załadowaną broń w trakcie przerw?</h3>
            <p>Nie. Odkładanie załadowanej broni w czasie przerw jest bezwzględnie zabronione – stanowi poważne zagrożenie dla bezpieczeństwa.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>70. ⏸️ Jak można odłożyć broń w trakcie konkurencji?</h3>
            <p>Wyłącznie po wyjęciu magazynka i naboju z komory nabojowej. Lufa musi być skierowana w stronę tarczy.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>71. 🛑 Co robić po komendzie „STOP”?</h3>
            <p>Bezwzględnie przerwać strzelanie. Każda sekunda zwłoki może prowadzić do niebezpiecznej sytuacji.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>72. 📥 Co oznacza komenda „ŁADUJ”?</h3>
            <p>Załadować magazynek, włożyć go do broni, wprowadzić nabój do komory i czekać na kolejną komendę. Nie wolno strzelać bez wyraźnej komendy.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>73. 🎯 Czy można „strzelać na sucho”?</h3>
            <p>Tak – ale wyłącznie na stanowisku strzeleckim lub treningowym i za zgodą prowadzącego.</p>
          </li>
        </ul>

        <h2>👮 Obowiązki i zasady dla uczestników</h2>
        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>74. 🚷 Czy prowadzący może usunąć uczestnika?</h3>
            <p>Tak, prowadzący ma pełne prawo usunąć ze strzelnicy osobę, która nie stosuje się do poleceń i regulaminu.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>75. 📦 Co należy zrobić z bronią po zakończeniu strzelania?</h3>
            <p>Rozładować ją, zabezpieczyć i opuścić stanowisko tylko z otwartą komorą nabojową. Broń nie może być załadowana.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>76. 📛 Która komenda oznacza natychmiastowe przerwanie strzelania?</h3>
            <p>Komenda „STOP”. Każdy uczestnik musi bezwzględnie zareagować natychmiast.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>77. 🔓 Kiedy rozładować i zabezpieczyć broń?</h3>
            <p>Po komendzie „ROZŁADUJ”, broń należy rozbroić i odłożyć w bezpieczny sposób.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>78. 🔫 Co robić po komendzie „ŁADUJ”?</h3>
            <p>Załadować broń, wprowadzić nabój do komory i czekać. Strzelanie można rozpocząć dopiero po kolejnej komendzie prowadzącego.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>79. ✔️ Co robić po zakończeniu strzelania?</h3>
            <p>Broń należy rozładować, pokazać prowadzącemu do kontroli, a następnie opuścić stanowisko z bronią z otwartą komorą.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>80. 🐦 Czy można celować do przedmiotów innych niż cele?</h3>
            <p>Nie. Strzelanie i celowanie do ludzi, zwierząt lub nieprzeznaczonych celów jest absolutnie zabronione.</p>
          </li>
        </ul>

        <h2>📋 Obowiązki prowadzącego i regulamin</h2>
        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>81. 👮 Jakie obowiązki ma prowadzący strzelanie?</h3>
            <p>
              Odpowiada za bezpieczeństwo wszystkich obecnych, prowadzi książkę rejestru, wydaje komendy, kontroluje rozładowanie broni i egzekwuje regulamin.
            </p>
          </li>

          <li className={styles.customAnswer}>
            <h3>82. ✍️ Czy uczestnicy muszą zapoznać się z regulaminem?</h3>
            <p>Tak, każdy użytkownik strzelnicy musi podpisać oświadczenie o zapoznaniu się z regulaminem i zasadami bezpieczeństwa.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>83. 🚫 Jakie zachowania są bezwzględnie zakazane?</h3>
            <p>
              Osoby towarzyszące nie mogą wchodzić na stanowiska strzeleckie ani dotykać broni. Używanie cudzej broni bez zgody właściciela również jest zakazane.
            </p>
          </li>
        </ul>

        <div className="buttonContainer">
          <button onClick={handleNext} className={styles.secondaryButton}>
            👉 Kolejne pytania
          </button>
          <button onClick={() => navigate('/')} className={styles.primaryButton}>
            Rozwiąż testy z prawdziwych egzaminów na broń
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamQuestions5;
