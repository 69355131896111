// src/hooks/useGameTimer.js
import { useState, useEffect, useRef } from 'react';

export const useGameTimer = (
  gameStarted,         // czy gra się rozpoczęła
  gameOver,            // czy gra się zakończyła
  currentQuestionIndex,
  bonusTime,
  handleTimeOut,
  startTimer,          // kontrola uruchomienia timera (np. zawsze true, gdy chcemy odliczania)
  baseTime,            // czas na pytanie (w sekundach)
  shouldRun            // dodatkowy flag kontrolujący działanie timera (np. !isChecked)
) => {
  const GAME_TIME = baseTime || 15;
  const [timeLeft, setTimeLeft] = useState(GAME_TIME + bonusTime);
  const intervalRef = useRef(null);

  // Resetuj timer tylko przy zmianie pytania lub bonusTime.
  useEffect(() => {
    setTimeLeft(GAME_TIME + bonusTime);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [currentQuestionIndex, bonusTime, GAME_TIME]);

  useEffect(() => {
    // Jeśli nie powinno działać, wyczyść interwał.
    if (!gameStarted || gameOver || !startTimer || !shouldRun) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      return;
    }

    // Uruchom interwał, jeśli jeszcze nie działa.
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            handleTimeOut();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [gameStarted, gameOver, startTimer, shouldRun, handleTimeOut]);

  return timeLeft;
};
