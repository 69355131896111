// src/components/EnergyBar.jsx
import React from 'react';
import styles from '../css/modules/EnergyBar.module.css';

const EnergyBar = ({ current, label }) => {
  // Zakładamy, że "current" jest już procentem (0-100)
  const percentage = current;
  let color;
  if (percentage > 50) {
    color = '#00ff00'; // zielony
  } else if (percentage > 25) {
    color = '#ffaa00'; // pomarańczowy
  } else {
    color = '#ff0000'; // czerwony
  }

  return (
    <div className={styles.energyBarContainer}>
      <span className={styles.label}>{label}</span>
      <div 
        className={styles.barBackground} 
        title={`Energia ${percentage}%`} // Przeglądarkowy tooltip
      >
        <div 
          className={styles.barFill} 
          style={{ width: `${percentage}%`, backgroundColor: color }} 
        />
      </div>
    </div>
  );
};

export default EnergyBar;
