// src/ExamQuestions9.jsx
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../css/modules/ExamQuestions.module.css';

const ExamQuestions9 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/');
  };

  return (
    <div className={styles.contactContainer}>
      <Helmet>
        <title>Patent strzelecki – odpowiedzialność karna i granice obrony (część 9)</title>
        <meta
          name="description"
          content="Ostatnia część pytań egzaminacyjnych na patent strzelecki. Sprawdź, jakie są konsekwencje prawne związane z bronią, granice obrony koniecznej i stanu wyższej konieczności."
        />
      </Helmet>

      <div className={styles.content}>
        <h1 className="mainHeader">🎯 Pytania egzaminacyjne – odpowiedzialność karna i obrona (część 9)</h1>

        <div className="intro">
          📘 Ostatnia seria pytań dotyczy odpowiedzialności karnej związanej z bronią oraz granic obrony koniecznej i stanu wyższej konieczności.
          Spróbuj swoich sił – zobacz, jak poradzisz sobie z prawdziwymi pytaniami egzaminacyjnymi. Testy w formie gry - spodoba Ci się:)
        </div>

        <h2>⚖️ Przestępstwa i wykroczenia związane z bronią</h2>

        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>131. 📩 Co grozi za brak zgłoszenia zbycia lub utraty broni/amunicji?</h3>
            <p>Podlega karze aresztu lub grzywny – obowiązek zgłoszenia istnieje niezależnie od przyczyny utraty broni.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>132. 🏠 Co grozi za brak zgłoszenia zmiany miejsca stałego pobytu?</h3>
            <p>Jeśli posiadasz broń lub pozwolenie – masz 14 dni na pisemne zgłoszenie zmiany. W przeciwnym razie grozi kara aresztu lub grzywny.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>133. 🧯 Co grozi za nieumyślną utratę broni lub amunicji?</h3>
            <p>Grzywna, ograniczenie wolności lub pozbawienie wolności do 1 roku – odpowiedzialność istnieje nawet przy braku złej woli.</p>
          </li>
        </ul>

        <h2>🛡️ Obrona konieczna i stan wyższej konieczności</h2>

        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>134. ⚖️ Czym jest stan wyższej konieczności?</h3>
            <p>Działanie mające na celu uchylenie bezpośredniego niebezpieczeństwa, poświęcając dobro o mniejszej wartości, gdy nie ma innego sposobu uniknięcia zagrożenia.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>135. 🛡️ Czym jest obrona konieczna?</h3>
            <p>Odpieranie bezpośredniego, bezprawnego zamachu na jakiekolwiek dobro chronione prawem, np. życie, zdrowie, mienie.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>136. 🙏 Czy sąd może odstąpić od kary za przekroczenie obrony koniecznej?</h3>
            <p>Tak – jeżeli przekroczenie wynikało ze strachu lub wzburzenia usprawiedliwionego okolicznościami.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>137. 🧠 Czy sąd może złagodzić karę za przekroczenie stanu wyższej konieczności?</h3>
            <p>Tak – jeśli działanie było motywowane ochroną dobra przed zamachem, ale sposób obrony był niewspółmierny do zagrożenia.</p>
          </li>
        </ul>

        <h2>🚫 Naruszenia przepisów i ich konsekwencje</h2>

        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>138. 🔒 Co grozi za noszenie broni z naruszeniem ograniczenia lub zakazu?</h3>
            <p>Areszt lub grzywna – dotyczy to również złamania zakazu ustanowionego przez ministra spraw wewnętrznych.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>139. 🎯 Co grozi za użycie broni poza strzelnicą w celach szkoleniowych/sportowych?</h3>
            <p>Podlega karze aresztu lub grzywny – używanie broni możliwe jest wyłącznie na strzelnicach.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>140. 🛂 Co grozi za przywóz broni spoza UE bez dokumentu konsula?</h3>
            <p>Areszt lub grzywna – wymagane jest zaświadczenie wydane przez konsula RP, potwierdzające legalność przywozu.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>141. 🧨 Co grozi za produkcję lub handel bronią bez zezwolenia?</h3>
            <p>Pozbawienie wolności od 1 roku do 10 lat – to jedno z najpoważniejszych przestępstw w obrocie bronią.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>142. 🔫 Co grozi za nielegalne posiadanie broni palnej lub amunicji?</h3>
            <p>Kara pozbawienia wolności od 6 miesięcy do 8 lat – bezwzględny wymiar odpowiedzialności karnej.</p>
          </li>
        </ul>

        <div className="buttonContainer">
          <button onClick={() => navigate('/')} className={styles.primaryButton}>
            Sprawdź testy na patent strzelecki
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamQuestions9;
