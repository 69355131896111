import { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../components/UserContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useUserInfo = () => {
  const { setUser } = useUser();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      const fetchUserInfo = async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/cyber/user_info`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          // Set local user info state
          setUserInfo(response.data);

          // Update global user context with comprehensive data
          setUser(prevUser => ({
            ...prevUser,
            score: response.data.score ?? prevUser?.score ?? 0,
            energy: response.data.energy ?? prevUser?.energy ?? 100,
            artefacts: response.data.artefacts ?? prevUser?.artefacts ?? [],
            image: response.data.image ?? prevUser?.image ?? 'my-photo.webp',
            name: response.data.name ?? prevUser?.name ?? 'Gracz',
            level: response.data.level ?? prevUser?.level ?? 0,
            test_access_till: response.data.test_access_till,
            categories: response.data.categories ?? prevUser?.categories ?? [],
          }));
        } catch (error) {
          console.error("Błąd podczas pobierania informacji o użytkowniku:", error);
          // Reset user info on error
          setUserInfo(null);
        } finally {
          setLoading(false);
        }
      };

      fetchUserInfo();
    } else {
      setLoading(false);
    }
  }, [setUser]);

  return { userInfo, loading };
};