// src/QuizCyberpunkSingle.jsx
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import QuizContent from './QuizContent';
import { useQuestions } from '../hooks/useQuestions';
import { useGameTimer } from '../hooks/useGameTimer';
import { useUser } from './UserContext';
import styles from '../css/modules/QuizCyberpunkDriving.module.css';

const INITIAL_HEALTH = 100; // Domyślna energia użytkownika
const DAMAGE_PER_HIT = 25;

// Funkcja normalizująca – zamienia wartość na string, usuwa białe znaki i zmienia na małe litery.
const normalize = (value) => value.toString().trim().toLowerCase();

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const QuizCyberpunkSingle = () => {
  const { enemySlug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, setUser } = useUser();

  // Dane przekazywane z IntroEnemy
  const passedEnemyData = location.state?.enemyData;
  const passedUserData = location.state?.userData;

  // Inicjalizacja danych przeciwnika
  const initialEnemyData = passedEnemyData
    ? {
        ...passedEnemyData,
        energy: passedEnemyData.health !== undefined
          ? passedEnemyData.health
          : passedEnemyData.energy,
        time: passedEnemyData.time !== undefined ? passedEnemyData.time : 15,
        strength: passedEnemyData.strength !== undefined ? passedEnemyData.strength : 100,
      }
    : {
        image: `/enemies/${enemySlug}.webp`,
        name: 'Przeciwnik',
        energy: 100,
        strength: 100,
        time: 15,
        slug: enemySlug,
      };

  // Inicjalizacja stanu gry – ustawiane przy montowaniu
  const initialUserScore = user ? user.score : (passedUserData?.score ?? 0);
  const initialUserHealth = user ? user.energy : (passedUserData?.energy ?? INITIAL_HEALTH);

  const { questions, error } = useQuestions();

  // Lokalny stan gry
  const [score, setScore] = useState(initialUserScore);
  const [health, setHealth] = useState(initialUserHealth);
  const [bonusTime, setBonusTime] = useState(0);
  const [shieldCount, setShieldCount] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isQuestionFinished, setIsQuestionFinished] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [enemyState, setEnemyState] = useState(initialEnemyData);
  const [userKey, setUserKey] = useState(Date.now());
  const [hasNavigated, setHasNavigated] = useState(false);
  // Stan odpowiedzialny za widoczność okna z wyjaśnieniem
  const [explanationVisible, setExplanationVisible] = useState(false);

  // Efekt inicjalizacyjny – ustawiamy dane użytkownika tylko jeśli jeszcze nie gra
  useEffect(() => {
    if (user) {
      if (!user.isPlaying) {
        setUser((prevUser) => ({
          ...prevUser,
          isPlaying: true,
        }));
        setUserKey(Date.now());
        setHealth(user.energy ?? INITIAL_HEALTH);
        setScore(user.score ?? 0);
      }
    } else {
      setScore(passedUserData?.score ?? 0);
      setHealth(passedUserData?.energy ?? INITIAL_HEALTH);
    }
  }, [passedUserData, setUser]);

  const timerRef = useRef(null);

  const gameOverHandler = useCallback(() => {
    setHealth(0);
  }, []);

  // Obsługa ataku: sprawdzamy odpowiedź, aktualizujemy stany i zapisujemy dane do bazy
  const onAttackHandler = useCallback(() => {
    clearInterval(timerRef.current);
    setIsChecked(true); // Po udzieleniu odpowiedzi wyświetlamy BottomBar
    const currentQuestion = questions[currentQuestionIndex];
    if (!currentQuestion || !currentQuestion.correctAnswer) {
      console.error('Brak danych pytania');
      return;
    }
    let isCorrect = false;
    if (Array.isArray(currentQuestion.correctAnswer)) {
      isCorrect =
        selectedAnswers.length === currentQuestion.correctAnswer.length &&
        currentQuestion.correctAnswer.every((ans) =>
          selectedAnswers.some(
            (selected) => normalize(selected) === normalize(ans)
          )
        );
    } else {
      isCorrect =
        selectedAnswers.length === 1 &&
        normalize(selectedAnswers[0]) === normalize(currentQuestion.correctAnswer);
    }
    console.log('onAttackHandler:', {
      selectedAnswers,
      correctAnswer: currentQuestion.correctAnswer,
      isCorrect,
    });

    if (isCorrect) {
      setScore((prev) => {
        const newScore = prev + 10;
        updateUserInfoInDB({ score: newScore, energy: health });
        return newScore;
      });
      if (user && typeof setUser === 'function') {
        setUser((prevUser) => ({
          ...prevUser,
          score: (prevUser.score || 0) + 10,
        }));
      }
      setEnemyState((prev) => ({
        ...prev,
        energy: Math.max(prev.energy - DAMAGE_PER_HIT, 0),
      }));
    } else {
      if (shieldCount > 0) {
        setShieldCount((prev) => prev - 1);
      } else {
        setHealth((prev) => {
          const damage = enemyState.strength || 100;
          const newHealth = Math.max(prev - damage, 0);
          if (newHealth <= 0) {
            gameOverHandler();
          }
          updateUserInfoInDB({ score, energy: newHealth });
          return newHealth;
        });
        if (user && typeof setUser === 'function') {
          setUser((prevUser) => ({
            ...prevUser,
            energy: Math.max((prevUser.energy || INITIAL_HEALTH) - (enemyState.strength || 100), 0),
          }));
        }
      }
    }
  }, [
    questions,
    currentQuestionIndex,
    selectedAnswers,
    shieldCount,
    enemyState.strength,
    gameOverHandler,
    setUser,
    user,
    health,
    score,
  ]);

  // Automatyczne sprawdzanie odpowiedzi przy wybraniu jednej opcji
  useEffect(() => {
    if (selectedAnswers.length === 1 && !isChecked) {
      onAttackHandler();
    }
  }, [selectedAnswers, isChecked, onAttackHandler]);

  // Obsługa upływu czasu – jeśli użytkownik nie odpowie, traktujemy to jako błędną odpowiedź
  const handleTimeOut = useCallback(() => {
    if (!isChecked) {
      onAttackHandler();
    }
  }, [isChecked, onAttackHandler]);

  const timeLeft = useGameTimer(
    true,      // gra rozpoczęta
    false,     // gra trwa
    currentQuestionIndex,
    bonusTime,
    handleTimeOut,
    true,      // uruchom timer
    enemyState.time,
    !isChecked // timer zatrzymany, gdy odpowiedź została udzielona
  );

  // Callback po zakończeniu animacji pisania pytania
  const onTypingEndHandler = useCallback(() => {
    setIsQuestionFinished(true);
  }, []);

  // Funkcja obsługująca wybór odpowiedzi – zawsze tylko jedna odpowiedź
  const onToggleAnswerHandler = useCallback((key) => {
    setSelectedAnswers([key]);
  }, []);

  const onPreviousHandler = useCallback(() => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
      setSelectedAnswers([]);
      setIsChecked(false);
      setIsQuestionFinished(false);
      // Ukrywamy wyjaśnienie przy zmianie pytania
      setExplanationVisible(false);
    }
  }, [currentQuestionIndex]);

  // Funkcja przełączająca widoczność okna z wyjaśnieniem
  const onToggleExplanation = useCallback(() => {
    setExplanationVisible((prev) => !prev);
  }, []);

  // Nawigacja po zakończeniu gry
  const handleDefeated = () => {
    if (enemyState && !hasNavigated) {
      setHasNavigated(true);
      window.location.href = '/defeated';
    }
  };

  const handleSuccess = () => {
    if (enemyState && !hasNavigated) {
      setHasNavigated(true);
      window.location.href = `/${enemyState.slug}/success`;
    }
  };

  const onNextHandler = useCallback(() => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setSelectedAnswers([]);
      setIsChecked(false);
      setIsQuestionFinished(false);
      // Ukrywamy wyjaśnienie przy przejściu do kolejnego pytania
      setExplanationVisible(false);
    } else {
      if (!hasNavigated) {
        setHasNavigated(true);
        window.location.href = `/${enemyState.slug}/tie`;
      }
    }
  }, [currentQuestionIndex, questions.length, hasNavigated, enemyState.slug]);

  // Funkcja aktualizująca dane użytkownika w bazie
  const updateUserInfoInDB = async (updatedData) => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_BASE_URL}/cyber/user_info`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      });
      if (!response.ok) {
        throw new Error('Błąd aktualizacji danych użytkownika');
      }
      const data = await response.json();
      console.log('User info updated in DB:', data);
    } catch (error) {
      console.error('Error updating user info in DB:', error);
    }
  };

  const userData = {
    image: user?.image || (passedUserData?.image || '/my-photo.webp'),
    name: user?.name || (passedUserData?.name || 'Gracz'),
    energy: health,
    maxEnergy: INITIAL_HEALTH,
    score: score,
  };

  const enemyData = enemyState;

  // Przekierowanie, gdy gra się kończy
  useEffect(() => {
    if (health <= 0 && !hasNavigated) {
      handleDefeated();
    } else if (enemyData.energy <= 0 && !hasNavigated) {
      handleSuccess();
    }
  }, [health, enemyData.energy, hasNavigated, enemyData]);

  if (error) return <div>Błąd: {error}</div>;
  if (questions.length === 0 || !questions[currentQuestionIndex])
    return (
      <div className={styles.loadingContainer}>
        <span className={styles.loadingText}>Ładowanie pytań...</span>
      </div>
    );

  return (
    <QuizContent
      userData={userData}
      enemyData={enemyData}
      currentQuestion={questions[currentQuestionIndex]}
      selectedAnswers={selectedAnswers}
      isChecked={isChecked}
      onToggleAnswer={onToggleAnswerHandler}
      timeLeft={timeLeft}
      isQuestionFinished={isQuestionFinished}
      // Kluczowy element: BottomBar (renderowany w QuizContent) pojawi się, gdy isChecked === true
      areAnswersFinished={isChecked}
      onTypingEnd={onTypingEndHandler}
      onPrevious={onPreviousHandler}
      onAttack={onAttackHandler}
      onNext={onNextHandler}
      // Przekazujemy stan widoczności wyjaśnienia oraz funkcję do jego przełączania
      explanationVisible={explanationVisible}
      onToggleExplanation={onToggleExplanation}
      userKey={userKey} // Klucz wymuszający odświeżenie UserWindow
    />
  );
};

export default QuizCyberpunkSingle;
