// src/components/StartScreen.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../css/modules/StartScreen.module.css';

const StartScreen = () => {
  const navigate = useNavigate();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const categories = ['A', 'B', 'C', 'D', 'T', 'AM', 'A1', 'A2', 'B1', 'C1', 'D1'];

  const handleCategoryClick = (category) => {
    setSelectedCategories(prev =>
      prev.includes(category)
        ? prev.filter(cat => cat !== category)
        : [...prev, category]
    );
  };

  const saveUserPreferences = async () => {
    try {
      setIsLoading(true);
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const token = localStorage.getItem('access_token');

      if (!token) {
        console.log('Użytkownik nie zalogowany, kategorie zapisane tylko lokalnie');
        return;
      }

      const updatedData = {
        preferred_categories: selectedCategories
      };

      const response = await fetch(`${API_BASE_URL}/cyber/user_info`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        throw new Error('Błąd zapisywania preferencji użytkownika');
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleStart = async () => {
    sessionStorage.setItem('selectedCategories', JSON.stringify(selectedCategories));
    await saveUserPreferences();
    console.log('Wybrane kategorie:', selectedCategories);
    navigate('/intro');
  };

  return (
    <div className={styles.container}>
      <video 
        autoPlay 
        muted 
        loop 
        playsInline 
        webkitplaysinline
        className={styles.backgroundVideo}
      >
        <source src="/backgrounds/shooting_video.mp4" type="video/mp4" />
        Twoja przeglądarka nie obsługuje odtwarzania wideo.
      </video>
      <div className={styles.overlay}></div>
      <div className={styles.content}>
        <h1>Testy na  patent strzelecki - pozwolenie na broń</h1>
        <p className={styles.description}>
          Ucz się grając w cyberpunkową grę.
        </p>
        
        <h2>Wybierz Kategorie:</h2>
        <div className={styles.categoriesGrid}>
          {categories.map((category) => (
            <button
              key={category}
              className={`${styles.categoryButton} ${
                selectedCategories.includes(category) ? styles.selectedCategory : ''
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
        
        {selectedCategories.length > 0 && (
          <button 
            className={styles.startButton} 
            onClick={handleStart}
            disabled={isLoading}
          >
            {isLoading ? 'Ładowanie...' : 'Rozpocznij Test'}
          </button>
        )}
      </div>
    </div>
  );
};

export default StartScreen;
