import React, { useState, useEffect } from 'react';
import '../css/modules/Typewriter.module.css';

const Typewriter = ({ text, typingSpeed = 100, onTypingEnd }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      setDisplayedText(text.substring(0, currentIndex + 1));
      currentIndex++;
      if (currentIndex === text.length) {
        clearInterval(interval);
        if (onTypingEnd) {
          onTypingEnd(); // Wywołujemy callback po zakończeniu pisania
        }
      }
    }, typingSpeed);

    return () => clearInterval(interval);
  }, [text, typingSpeed, onTypingEnd]);

  return (
    <div className="typewriter-container">
      {displayedText}
    </div>
  );
};

export default Typewriter;
