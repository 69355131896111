// src/CongratsScreen.jsx
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import styles from '../css/modules/CongratsScreen.module.css';
import Typewriter from './Typewriter';
import { useUser } from './UserContext';

const defaultUserData = {
  image: '/my-photo.webp',
  name: 'Gracz',
  energy: 100,
  score: 550,
  ranking: 0,
  level: 1,
  artefacts: [],
};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CongratsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enemySlug } = useParams(); // Pobieramy enemySlug z URL, np. "/tomo/success"
  const { user } = useUser();

  // Jeśli dane przeciwnika są przekazywane przez state, używamy ich
  // W przeciwnym razie spróbujemy pobrać je z API na podstawie enemySlug
  const [enemyData, setEnemyData] = useState(location.state?.enemy || null);
  const [loadingEnemy, setLoadingEnemy] = useState(false);

  useEffect(() => {
    if (!enemyData && enemySlug) {
      setLoadingEnemy(true);
      fetch(`${API_BASE_URL}/cyber/get_enemy_by_slug`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ slug: enemySlug }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Błąd pobierania danych przeciwnika');
          }
          return response.json();
        })
        .then((data) => {
          setEnemyData(data);
          setLoadingEnemy(false);
        })
        .catch((error) => {
          console.error('Błąd podczas pobierania danych przeciwnika:', error);
          setLoadingEnemy(false);
        });
    }
  }, [enemyData, enemySlug]);

  // Dane użytkownika – korzystamy z kontekstu; jeśli brak, używamy danych domyślnych.
  const finalUserData = user || defaultUserData;
  const finalScore = finalUserData.score || 0;
  const finalUserName = finalUserData.name || 'Gracz';

  // Modyfikacja obrazu użytkownika: upewniamy się, że zaczyna się od "/" i kończy na ".webp"
  let rawImage = finalUserData.image || 'my-photo.webp';
  if (!rawImage.startsWith('/')) {
    rawImage = '/' + rawImage;
  }
  if (!rawImage.endsWith('.webp')) {
    rawImage = rawImage + '.webp';
  }
  const finalUserImage = rawImage;

  // Losujemy tło na podstawie liczby dostępnych obrazów
  const bgImagesCount = Number(process.env.REACT_APP_BACKGROUND_IMAGES_SIZE) || 5;
  const randomBgNumber = Math.floor(Math.random() * bgImagesCount) + 1;
  const backgroundImageUrl = `/backgrounds/${randomBgNumber}.webp`;

  const containerStyle = {
    backgroundImage: `linear-gradient(135deg, rgba(13, 13, 13, 0.7), rgba(26, 26, 26, 0.7)), url(${backgroundImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  if (loadingEnemy) {
    return (
      <div className={styles.congratsContainer} style={containerStyle}>
        Ładowanie danych przeciwnika...
      </div>
    );
  }

  const finalEnemy = enemyData || { slug: enemySlug || 'default', name: 'Przeciwnik' };

  // Funkcja obsługująca przycisk – jeśli użytkownik jest zalogowany, kontynuujemy grę,
  // w przeciwnym razie przekierowujemy na stronę cennika.
  const handleContinue = () => {
    if (user) {
      navigate('/intro', { state: { userData: finalUserData } });
    } else {
      navigate('/cennik');
    }
  };

  return (
    <div className={styles.congratsContainer} style={containerStyle}>
      <img 
        src={`/enemies/${finalEnemy.slug}.webp`} 
        alt={`Pokonany przeciwnik: ${finalEnemy.name}`} 
        className={styles.enemyImage} 
      />
      <h1 className={styles.congratsMessage}>
        <Typewriter 
          text={`Gratulacje! Pokonałeś ${finalEnemy.name}!`} 
          typingSpeed={100} 
        />
      </h1>
      <p className={styles.scoreMessage}>Twój wynik: {finalScore} punktów</p>
      <p>Odkryj sekrety przyszłości – zarejestruj się i rozpocznij grę!</p>
      
      <div className={styles.buttonContainer}>
        <button 
          className={styles.continueButton} 
          onClick={handleContinue}
        >
          {user ? 'Walcz dalej' : 'KUP DOSTĘP'}
        </button>
        <Link 
          to="/shop" 
          className={styles.continueButton}
        >
          Sklep
        </Link>
      </div>
    </div>
  );
};

export default CongratsScreen;
