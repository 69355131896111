import React, { createContext, useContext, useState } from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  const addToCart = (item) => {
    // Każda instancja produktu powinna mieć unikalny identyfikator,
    // nawet jeśli produkty są takie same – możesz to osiągnąć np. generując unikalny id.
    setCartItems((prevItems) => [...prevItems, item]);
  };

  const removeFromCart = (id) => {
    setCartItems((prevItems) => {
      const index = prevItems.findIndex(item => item.id === id);
      if (index === -1) return prevItems;
      return [
        ...prevItems.slice(0, index),
        ...prevItems.slice(index + 1)
      ];
    });
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart }}>
      {children}
    </CartContext.Provider>
  );
};
