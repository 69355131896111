import React from 'react';
import Modal from './Modal';
import { useCart } from '../components/CartContext';
import styles from '../css/modules/CartModal.module.css';

const CartModal = ({ isOpen, onClose }) => {
  const { cartItems, removeFromCart } = useCart();

  const handlePayment = () => {
    // Przyjmujemy, że w koszyku jest jeden produkt,
    // a jego właściwość "duration" odpowiada liczbie dni ("10", "30", "90")
    const selectedDays = cartItems.length > 0 ? cartItems[0].duration : '';
    // Przekierowanie na stronę zakup z ustawionym parametrem days
    window.location.href = `/zakup?days=${selectedDays}`;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.cartModal}>
        <h2>Twój koszyk</h2>
        {cartItems.length === 0 ? (
          <p>Twój koszyk jest pusty.</p>
        ) : (
          <ul className={styles.cartList}>
            {cartItems.map(item => (
              <li key={item.id} className={styles.cartItem}>
                <div className={styles.itemInfo}>
                  <strong>{item.name}</strong> ({item.duration} dni) - {item.price}
                  <p>{item.description}</p>
                </div>
                <button
                  onClick={() => removeFromCart(item.id)}
                  className={styles.removeButton}
                >
                  Usuń
                </button>
              </li>
            ))}
          </ul>
        )}
        {cartItems.length > 0 && (
          <button className={styles.payButton} onClick={handlePayment}>
            Przejdź do płatności
          </button>
        )}
      </div>
    </Modal>
  );
};

export default CartModal;
