// Modal.js
import React from 'react';
import styles from '../css/modules/Modal.module.css';

const Modal = ({ children, isOpen, onClose, extraClass = '' }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modal}>
      <div className={`${styles.modalContent} ${extraClass}`}>
        <button className={styles.closeButton} onClick={onClose}>
          X
        </button>
        <div className={styles.scrollableContent}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
