// src/components/WhyToBuyUs.jsx
import React, { useRef } from 'react';
import styles from '../css/modules/WhyToBuyUs.module.css';
import { useUser } from './UserContext';

const customerReviews = [
  {
    id: 1,
    name: 'Magda Kwiatkowska',
    nick: 'MagKwit',
    answered: '1101',
    review: 'No i to jest nowatorskie podejście! Zabawnie i profesjonalnie. Pistolet.org uratował mój egzaminn na broń! 🚀 Wyjaśnienia do pytań są boskie! Zamiast wertować ustawy, po prostu grałam w testy. Wszystko weszło naturalnie – ustawa o broni i kodeks karny już mi niestraszne!',
    avatar: '/people/magda_kwiatkowska.webp'
  },
  {
    id: 2,
    name: 'Robert Lis',
    nick: 'Lisek92',
    answered: '954',
    review: 'Szacun! Zabawne, stop nudnym testom! Teoretyczny egzamin na broń to była moja zmora, ale Pistolet.org zrobił z tego misję specjalną. Super klimat, konkretna wiedza. Polecam każdemu kandydatowi!',
    avatar: '/people/robert_lis.webp'
  },
  {
    id: 3,
    name: 'Katarzyna Domańska',
    nick: 'Kasia_D',
    answered: '876',
    review: 'Nigdy nie sądziłam, że przepisy prawne mogą być ciekawe... a jednak! Mega ważne są wyjaśnienia do pytań! Dzięki Pistolet.org przyswoiłam wszystko szybciej niż myślałam. Ustawa o broni i amunicji, kodeks karny, definicje – ogarnięte.',
    avatar: '/people/katarzyna_domanska.webp'
  },
  {
    id: 4,
    name: 'Łukasz Jabłoński',
    nick: 'LJablon',
    answered: '992',
    review: 'Pistolet.org to petarda! 🔥 Uczyłem się do patentu strzeleckiego, grając – dosłownie. Zamiast nudnych PDF-ów, interaktywna zabawa. Doobre teksty ze znanych filmów! Egzamin zdany!',
    avatar: '/people/lukasz_jablonski.webp'
  },
  {
    id: 5,
    name: 'Agnieszka Malec',
    nick: 'AggieM',
    answered: '972',
    review: 'Testy na Pistolet.org zrobiły różnicę – wszystko przemyślane, logiczne i co najważniejsze: skuteczne. Polecam każdej osobie, która chce zdobyć pozwolenie na broń bez stresu.',
    avatar: '/people/agnieszka_malec.webp'
  },
  {
    id: 6,
    name: 'Michał Krajewski',
    nick: 'MKraj',
    answered: '1232',
    review: 'Ustawa o broni i amunicji? Kiedyś nie do przejścia. Teraz? Dzięki Pistolet.org znam ją lepiej niż własny PESEL. Polecam każdemu, kto chce uczyć się z głową!',
    avatar: '/people/michal_krajewski.webp'
  },
  {
    id: 7,
    name: 'Julia Pawlak',
    nick: 'JP_shooter',
    answered: '913',
    review: 'Zaczęłam z ciekawości, zostałam z pasji. Pistolet.org nie tylko przygotował mnie do egzaminu, ale nauczył rozumieć sens przepisów. Polecam z całego serca.',
    avatar: '/people/julia_pawlak.webp'
  },
  {
    id: 8,
    name: 'Adrian Wójcik',
    nick: 'A_Wojtek',
    answered: '547',
    review: 'Nie sądziłem, że można przygotować się do egzaminu na broń z takim luzem i frajdą. Pistolet.org to nie tylko baza wiedzy, to cały świat testów na broń. Szczególnie ważne są wyjaśnienia do pytań egzaminacyjnych. Zdane bez wahania!',
    avatar: '/people/adrian_wojcik.webp'
  },
  {
    id: 9,
    name: 'Emilia Król',
    nick: 'EmiK',
    answered: '831',
    review: 'Strzał w dziesiątkę! 💥 Pistolet.org sprawił, że egzamin na patent strzelecki był formalnością. Nie tylko nauczyłam się przepisów – zrozumiałam je i zapamiętałam na długo.',
    avatar: '/people/emilia_krol.webp'
  }
];


const WhyToBuyUs = () => {
  const scrollContainerRef = useRef(null);
  const { user } = useUser();

  const scrollLeft = () => {
    scrollContainerRef.current?.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollContainerRef.current?.scrollBy({ left: 300, behavior: 'smooth' });
  };

  const hasActiveAccess =
    user && user.test_access_till && new Date(user.test_access_till) > new Date();

  const daysRemaining = hasActiveAccess
    ? Math.ceil((new Date(user.test_access_till) - new Date()) / (1000 * 60 * 60 * 24))
    : 0;

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: 'url("/backgrounds/welcome2_bg.webp")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className={styles.overlay}></div>

      <div className={styles.content}>
        <h1 className={styles.mainHeader}>TESTY NA PATENT STRZELECKI</h1>
        <p className={styles.intro}>
          Nauka z poczuciem humoru – prosto, skutecznie i bez stresu.
        </p>

        <div className={styles.reasons}>
          <h2>Dlaczego wybrać Pistolet.org? </h2>
          <div className={styles.reasonRowReverse}>
            <img src="/backgrounds/ważne-pytania-testowe-na-egzamin.webp" alt="Przyjazne podejście" className={styles.reasonImage} />
            <div className={styles.reasonText}>
            Nasze testy to zbiór pytań z prawdziwych egzaminów na patent strzelecki. Nasi użytkownicy wysyłają nam pytania z ostatnich egzaminów. Z nami nauczysz, się całego materiału niezbędnego by zdać egzamin na broń. Tak, zaufajcie Lasce z "Chłopaki nie płaczą", on wie co mówi!
            </div>
          </div>
          <div className={styles.reasonRow}>
          <img src="/backgrounds/grucha_uczy.webp" alt="Symulacja egzaminu" className={styles.reasonImage} />
            <div className={styles.reasonText}>
            Przygotowuj się do egzaminu na patent strzelecki w nietuzinkowy sposób – z postaciami inspirowanymi ikonami popkultury. Grucha zadba o to, byś zapamiętał to, co najważniejsze! Ucz się razem z bohaterami znanymi z polskich i zagranicznych filmów, muzyki i świata polityki. Rozpoznasz, kto kryje się za daną postacią?
            </div>
          </div>
          <div className={styles.reasonRowReverse}>
            <img src="/backgrounds/stop_nudnym_testom.webp" alt="Lepsza zapamiętywalność" className={styles.reasonImage} />
            <div className={styles.reasonText}>
            Stop nudnym testom – jak to śpiewał Freddie Mercury na Live Aid. Z nami nauka to przygoda! Rywalizuj z innymi, zbieraj punkty, zdobywaj kolejne levele i odblokowuj niespodzianki. Za zgromadzoną kasę kupuj unikalne artefakty i baw się nauką, jak nigdy dotąd.
            My gwarantujemy lepszą zapamiętywalność i zdany egzamin na broń.
            </div>
          </div>
          <div className={styles.reasonRowReverse}>
          <div className={styles.reasonText}>
          Symulacja egzaminu – pytania jak na egzaminie: z ustawy o broni, kodeksu karnego i regulaminów.
              Przyjazne podejście – uczysz się z humorem i bez stresu. Tak, jak powinno być! Zrozumiałe wyjaśnienia – każdy temat wyjaśniamy prosto i jasno, bez prawniczego żargonu.
            </div>
            <img src="/backgrounds/powtorzyc_ustawe_o_broni.webp" alt="Przyjazne podejście" className={styles.reasonImage} />
          </div>
   


          <div className={styles.reasonRow}>
            <div className={styles.reasonText}>
              Wielu uważa, że to najlepsze testy na patent strzelecki. Niektórzy, nawet twierdzą, że Trump w chwili zamachu to właśnie krzyczał!
              
            </div>
            <img src="/backgrounds/najlepsze_testy_na_bron.webp" alt="Zrozumiałe wyjaśnienia" className={styles.reasonImage} />
          </div>

          
        </div>

        {hasActiveAccess ? (
          <div className={styles.buttonContainer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className={styles.accessInfo} style={{ color: 'grey', marginBottom: '10px' }}>
              Masz jeszcze <strong>{daysRemaining} {daysRemaining === 1 ? 'dzień' : 'dni'}</strong> dostępu. Wykorzystaj je dobrze!
            </div>
            <a href="/intro" className={styles.primaryButton}>
              <strong>ROZPOCZNIJ NAUKĘ</strong>
            </a>
          </div>
        ) : (
          <div className={styles.buttonContainer}>
            <a href="/cennik" className={styles.primaryButton}>
              <strong>KUP DOSTĘP</strong>
            </a>
            <a href="/intro" className={styles.secondaryButton}>
              ZOBACZ DEMO
            </a>
          </div>
        )}

        <div className={styles.reviewsSection}>
          <h2>Co mówią nasi kursanci? 🗣️</h2>
          <div className={styles.reviewsContainer}>
            <button className={styles.arrowButton} onClick={scrollLeft}>←</button>
            <div ref={scrollContainerRef} className={styles.reviewsScroll}>
              {customerReviews.map(review => (
                <div key={review.id} className={styles.reviewCard}>
                  <img src={review.avatar} alt={review.name} className={styles.avatar} />
                  <div className={styles.reviewContent}>
                    <h3>{review.name}</h3>
                    <h4>({review.nick})</h4>
                    <h5>Poprawnych odpowiedzi: {review.answered}</h5>
                    <p>{review.review}</p>
                  </div>
                </div>
              ))}
            </div>
            <button className={styles.arrowButton} onClick={scrollRight}>→</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyToBuyUs;