import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../css/modules/Register.module.css';

const Register = ({ closeModal }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptMarketing, setAcceptMarketing] = useState(false);
  const [acceptPartners, setAcceptPartners] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const WEBSITE = process.env.REACT_APP_WEBSITE;

  useEffect(() => {
    setAcceptTerms(selectAll);
    setAcceptMarketing(selectAll);
    setAcceptPartners(selectAll);
  }, [selectAll]);

  useEffect(() => {
    if (acceptTerms && acceptMarketing && acceptPartners) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [acceptTerms, acceptMarketing, acceptPartners]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsError(false);

    if (password.length < 4) {
      setMessage('Hasło musi mieć co najmniej 4 znaki.');
      setIsError(true);
      return;
    }

    if (password !== confirmPassword) {
      setMessage('Hasła nie są takie same.');
      setIsError(true);
      return;
    }

    if (!acceptTerms) {
      setMessage('Musisz zaakceptować regulamin.');
      setIsError(true);
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/cyber/register`, {
        name,
        email,
        password,
        code: code || undefined,
        website: WEBSITE,
        promo_notifications: acceptMarketing,
        commercial_consent: acceptPartners,
      });

      if (response.data.error) {
        setMessage(response.data.error);
        setIsError(true);
      } else {
        setMessage(response.data.message);
        setIsError(false);
        setTimeout(() => {
          if (closeModal) {
            closeModal();
          }
        }, 2000);
      }
    } catch (error) {
      const errorMsg =
        error.response && (error.response.data.error || error.response.data.message)
          ? (error.response.data.error || error.response.data.message)
          : 'Błąd rejestracji';
      setMessage(errorMsg);
      setIsError(true);
    }
  };

  return (
    <div className={styles.regContainer}>
      <div className={styles.regContent}>
        <h2 className={styles.heading}>Rejestracja</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            id="name"
            placeholder="Nazwa użytkownika"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            id="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            id="password"
            placeholder="Hasło"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            type="password"
            id="confirmPassword"
            placeholder="Potwierdź hasło"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <input
            type="text"
            id="code"
            placeholder="Kod (opcjonalnie)"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <div className={styles.regCheckboxGroup}>
            <div className={styles.regCheckboxItem}>
              <input
                type="checkbox"
                id="selectAll"
                checked={selectAll}
                onChange={(e) => setSelectAll(e.target.checked)}
              />
              <label htmlFor="selectAll">Zaznacz wszystkie</label>
            </div>
            <div className={styles.regCheckboxItem}>
              <input
                type="checkbox"
                id="acceptTerms"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
              />
              <label htmlFor="acceptTerms">
                Oświadczam, że zapoznałem się z regulaminem i akceptuję jego treść.
              </label>
            </div>
            <div className={styles.regCheckboxItem}>
              <input
                type="checkbox"
                id="promo_notifications"
                checked={acceptMarketing}
                onChange={(e) => setAcceptMarketing(e.target.checked)}
              />
              <label htmlFor="acceptMarketing">
                Chcę otrzymywać informacje o promocjach i specjalnych ofertach w serwisie.
              </label>
            </div>
          </div>
          <button
            className={styles.regButton}
            type="submit"
          >
            Zarejestruj
          </button>
          {message && (
            <p
              className={styles.regErrorMessage}
              style={{ color: isError ? 'var(--accent-color)' : 'inherit' }}
            >
              {message}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default Register;
