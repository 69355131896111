// src/ExamQuestions1.jsx
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../css/modules/ExamQuestions.module.css';

const ExamQuestions1 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/pytania-egzaminacyjne-na-patent-strzelecki');
  };

  return (
    <div className={styles.contactContainer}>
      <Helmet>
        <title>Patent strzelecki – podstawy prawa i rodzaje broni (część 1)</title>
        <meta
          name="description"
          content="Pierwszy zestaw pytań egzaminacyjnych na patent strzelecki – amunicja, broń palna i pneumatyczna, zasady nabywania oraz użytkowania zgodnie z przepisami prawa."
        />
      </Helmet>

      <div className={styles.content}>
        <h1 className="mainHeader">🎯 Pytania egzaminacyjne – amunicja i rodzaje broni (część 1)</h1>

        <div className="intro">
          📘 Zapoznaj się z podstawowymi pytaniami na temat amunicji, klasyfikacji broni i zasad nabywania oraz użytkowania zgodnie z obowiązującym prawem.
          Poniże pytania pochodzą z oficjalnej bazy pytań Polskiego Zwiążku Strzelectwa Sportowego (PZSS) i są to
          nasze autorskie odpowiedzi. Najważniejsze jednak aby rozwiązywać testy!
        </div>

        <h2>🔫 Broń i amunicja – definicje i podstawy prawne</h2>

        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>1. 💥 Jakie pociski zaliczają się do amunicji?</h3>
            <p>Amunicją są wyłącznie naboje przeznaczone do strzelania z broni palnej.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>2. ❗ Co to jest amunicja szczególnie niebezpieczna?</h3>
            <p>Amunicja szczególnie niebezpieczna to m.in.:</p>
            <ul>
              <li>🔸 z pociskami podkalibrowymi z tworzyw sztucznych,</li>
              <li>🔸 zawierająca materiały wybuchowe lub zapalające,</li>
              <li>🔸 wytworzona niefabrycznie lub przerobiona do innych celów.</li>
            </ul>
          </li>

          <li className={styles.customAnswer}>
            <h3>3. 🛒 Na jakiej podstawie można nabyć amunicję?</h3>
            <p>
              <ul>
                <li>🔸 Legitymacja posiadacza broni – dla określonego typu broni,</li>
                <li>🔸 Pisemne zamówienie od uprawnionego podmiotu handlującego bronią lub amunicją.</li>
              </ul>
            </p>
          </li>

          <li className={styles.customAnswer}>
            <h3>4. 🧳 Co można przenosić z bronią palną do celów sportowych?</h3>
            <p>Broń musi być:</p>
            <ul>
              <li>🔸 bez amunicji w komorze nabojowej,</li>
              <li>🔸 z niezaładowanymi magazynkami.</li>
            </ul>
          </li>

          <li className={styles.customAnswer}>
            <h3>5. 🗓️ Kiedy należy zarejestrować zakupioną broń?</h3>
            <p>W ciągu 5 dni od dnia zakupu, jeśli zakup został dokonany na podstawie zaświadczenia.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>6. 🔫 Jakie rodzaje broni uznaje się za broń palną?</h3>
            <p>
              <ul>
                <li>🔸 bojową,</li>
                <li>🔸 myśliwską,</li>
                <li>🔸 sportową,</li>
                <li>🔸 gazową,</li>
                <li>🔸 alarmową,</li>
                <li>🔸 sygnałową.</li>
              </ul>
            </p>
          </li>

          <li className={styles.customAnswer}>
            <h3>7. 💨 Czym jest broń pneumatyczna?</h3>
            <p>To urządzenie miotające pocisk energią sprężonego gazu, osiągające powyżej 17 J. Posiada celownik i może razić cel na odległość.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>8. ⚠️ Czym jest broń szczególnie niebezpieczna?</h3>
            <p>
              <ul>
                <li>🔸 broń samoczynna,</li>
                <li>🔸 broń z tłumikiem huku,</li>
                <li>🔸 broń bez cech identyfikacyjnych,</li>
                <li>🔸 broń z ukrytymi mechanizmami strzelania lub zamaskowana.</li>
              </ul>
            </p>
          </li>
        </ul>

        <h2>📋 Posiadanie i obrót bronią</h2>

        <ul className="reasonList">
          <li className={styles.customAnswer}>
            <h3>9. 👤 Kto może używać broni sportowej klubu?</h3>
            <p>Osoby nieposiadające pozwolenia – tylko w obecności instruktora lub osoby uprawnionej w ramach działalności klubu/stowarzyszenia.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>10. 🎯 Do jakich celów można używać broni palnej?</h3>
            <p>Do celów: szkoleniowych, sportowych, rekreacyjnych oraz pokazowych – zgodnie z pozwoleniem.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>11. 📄 Kto może posiadać broń palną?</h3>
            <p>
              <ul>
                <li>🔸 osoby cywilne – za zgodą komendanta wojewódzkiego Policji,</li>
                <li>🔸 żołnierze zawodowi – za zgodą komendanta Żandarmerii Wojskowej.</li>
              </ul>
            </p>
          </li>

          <li className={styles.customAnswer}>
            <h3>12. 📝 Na jakiej podstawie można posiadać broń pneumatyczną?</h3>
            <p>
              <ul>
                <li>🔸 karta rejestracyjna z komendy Policji – dla cywilów,</li>
                <li>🔸 decyzja Żandarmerii Wojskowej – dla żołnierzy.</li>
              </ul>
            </p>
          </li>

          <li className={styles.customAnswer}>
            <h3>13. 🔁 Czy można zbywać broń lub amunicję innym osobom?</h3>
            <p>Tak – ale tylko między osobami posiadającymi pozwolenie na ten sam rodzaj broni.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>14. 🛠️ Czy dozwolone są przeróbki broni?</h3>
            <p>Nie – przerabianie rodzaju, kalibru lub przeznaczenia broni jest zakazane.</p>
          </li>

          <li className={styles.customAnswer}>
            <h3>15. 🧩 Czy istotne części broni lub amunicji są traktowane jak broń?</h3>
            <p>Tak – zgodnie z przepisami są uznawane za broń lub amunicję i podlegają rejestracji oraz kontroli.</p>
          </li>
        </ul>

        <div className="buttonContainer">
          <button onClick={handleNext} className={styles.secondaryButton}>
            Kolejne
          </button>
          <button onClick={() => navigate('/')} className={styles.primaryButton}>
            Rozwiązuj testy na patent strzelecki
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamQuestions1;
