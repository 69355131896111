import React, { useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from './UserContext';
import styles from '../css/modules/Login.module.css';

const Login = ({ closeModal, switchToRegister }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { login } = useContext(UserContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/cyber/login`, { email, password });
      if (response.status === 200 && response.data.access_token) {
        const { access_token } = response.data;
        console.log('Received token:', access_token);
        try {
          login(access_token);
          closeModal();
        } catch (decodeError) {
          console.error('Problem with token decoding:', decodeError);
          setErrorMessage('Problem z dekodowaniem tokenu.');
        }
      } else {
        console.error('Login response was ok, but access_token is missing');
        setErrorMessage('Odpowiedź była poprawna, ale brakuje tokenu dostępu.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage(error.response?.data?.msg || 'Wystąpił błąd podczas logowania.');
    }
  };

  return (
    <>
      <h1>Logowanie</h1>
      <form onSubmit={handleLogin} className={styles["form-container"]}>
        <input
          className={styles["input-field"]}
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className={styles["input-field"]}
          type="password"
          placeholder="Hasło"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className={styles["login-button"]} type="submit">
          Login
        </button>
      </form>
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
      <p style={{ marginTop: '10px' }}>
        Nie masz jeszcze konta?{' '}
        <button
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            padding: 0,
          }}
          onClick={switchToRegister}
        >
          Zarejestruj się
        </button>
      </p>
    </>
  );
};

export default Login;
